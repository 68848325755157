import { select } from 'utils/dynamic';

export const SELECT_BOND_GELEM_REPORT_YIELD = select(
  'id',
  'name',
  'ratingGroup',
  'bondMidroogRank',
  'grossDuration',
  'symbol',
  'grossYieldToMaturity',
);
export const SELECT_BOND_GELEM_REPORT_YIELD_MARKET = select(
  'id',
  'name',
  'ratingGroup',
  'midroogRating',
  'grossDuration',
  'symbol',
  'grossYieldToMaturity',
);
export const SELECT_BOND_GELEM_REPORT_DELTA = select(
  'id',
  'name',
  'ratingGroup',
  'bondMidroogRank',
  'grossDuration',
  'symbol',
  'grossYieldToMaturityDeltaByDuration',
);
export const SELECT_BOND_GELEM_REPORT_DELTA_MARKET = select(
  'id',
  'name',
  'ratingGroup',
  'midroogRating',
  'grossDuration',
  'symbol',
  'grossYieldToMaturityDeltaByDuration',
);

export const SELECT_BOND_GELEM_REPORT = select(
  'id',
  'symbol',
  'equityTypeName',
  'name',
  'grossYieldToMaturity',
  'grossYieldToMaturityDeltaByDuration',
  'grossDuration',
  'issuerName',
  'issuerNum',

  'bondMidroogRank',
  'viewMidroogRatingRank',
  'bondMidroogOutlook',

  'bondMaalotRank',
  'bondMaalotOutlook',
  'branchName',
);
export const SELECT_BOND_GELEM_MARKET_REPORT = select(
  'id',
  'symbol',
  'equityTypeName',
  'name',
  'grossYieldToMaturity',
  'grossYieldToMaturityDeltaByDuration',
  'grossDuration',
  'midroogRating',
  'marketRating',
  'ratingGroupRank',
  'ratingRank',
  'maalotRating',
  'issuerName',
  'issuerNum',
  'branchName',
);

export const SELECT_BOND_GROSS_REPORT = select(
  'id',
  'symbol',
  'equityTypeName',
  'name',
  'grossYieldToMaturity',
  'grossYieldToMaturityDeltaByDuration',
  'grossDuration',
  'issuerName',
  'branchName',
  'issuerNum',
  'midroogRatingGroup',
  'midroogRatingGroupRank',
  'midroogRating',
  'yearMonth',
);

export const SELECT_BOND_GROSS_MARKET_REPORT = select(
  'id',
  'symbol',
  'equityTypeName',
  'name',
  'grossYieldToMaturity',
  'grossYieldToMaturityDeltaByDuration',
  'grossDuration',
  'issuerName',
  'branchName',
  'issuerNum',
  'ratingGroup',
  'ratingGroupRank',
  'midroogRating',
  'yearMonth',
  'maalotRating',
  'marketRating',
);

export const SELECT_BOND_GELEM_YIELD_REPORT = select(
  'id',
  'symbol',
  'name',
  'linkedAssetName',
  'issuerNum',
  'issuerName',
  'grossYieldToMaturity',
  'bondMidroogRank',
  'bondMidroogOutlook',
  'viewMidroogRatingRank',
  'bondMaalotRank',
  'bondMaalotOutlook',
  'viewMaalotRatingRank',
  'countryOfIncorporation',
);
