import ArchiveIcon from '@mui/icons-material/Archive';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InfoIcon from '@mui/icons-material/Info';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PublicIcon from '@mui/icons-material/Public';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import { Collapse, Typography, TypographyProps } from '@mui/material';
import { NativeScroll } from 'components/native-scroll';
import { APP_ROUTES } from 'configs';
import { useCurrentUser, useTranslate } from 'hooks';
import React, { useMemo } from 'react';
import { NavigationItem, NavigationOption } from '../navigation-item';

interface CategoryTitleProps extends TypographyProps {
  isOpen: boolean;
}
const CategoryTitle: React.FC<CategoryTitleProps> = ({ isOpen, ...rest }) => {
  return (
    <Collapse in={isOpen}>
      <Typography
        color={'textSecondary'}
        variant={'body1'}
        fontWeight={600}
        textTransform={'capitalize'}
        pl={'3rem'}
        pr={'1rem'}
        component={'div'}
        minWidth={250}
        {...rest}
      />
    </Collapse>
  );
};
interface Props {
  isMenuOpen: boolean;
  onMenuClose?: () => void;
  onMenuOpen?: () => void;
}

export const NavigationList: React.FC<Props> = ({ isMenuOpen, onMenuOpen, onMenuClose }) => {
  const { tp } = useTranslate();

  const { isSystemAlerts } = useCurrentUser();

  const optionsOverview = useMemo<NavigationOption[]>(() => {
    return [
      {
        title: tp('dashboard'),
        Icon: <DashboardIcon color={'inherit'} fontSize={'inherit'} />,
        to: APP_ROUTES.DASHBOARD().config,
      },
    ];
  }, [tp]);

  const optionsDataInformation = useMemo<NavigationOption[]>(() => {
    return [
      {
        title: tp('portfolio'),
        Icon: <BusinessCenterIcon color={'inherit'} fontSize={'inherit'} />,
        options: [
          { title: tp('delta'), to: APP_ROUTES.PORTFOLIO_DELTA().config },
          { title: tp('yield'), to: APP_ROUTES.PORTFOLIO_YIELD().config },
          { title: tp('bonds'), to: APP_ROUTES.PORTFOLIO_BONDS().config },
          { title: tp('stocks'), to: APP_ROUTES.PORTFOLIO_STOCKS().config },
        ],
      },
      {
        title: tp('online'),
        Icon: <OnlinePredictionIcon color={'inherit'} fontSize={'inherit'} />,
        options: [
          { title: tp('bonds'), to: APP_ROUTES.ONLINE_BONDS().config },
          { title: tp('stocks'), to: APP_ROUTES.ONLINE_STOCKS().config },
          { title: tp('alerts'), to: APP_ROUTES.ONLINE_ALERTS().config },
        ],
      },
      {
        title: tp('alerts'),
        Icon: <InfoIcon color={'inherit'} fontSize={'inherit'} />,
        options: [
          {
            title: tp('delta-performance'),
            to: APP_ROUTES.ALERTS_DELTA().config,
          },
          {
            title: tp('yield-performance'),
            to: APP_ROUTES.ALERTS_YIELD().config,
          },
          {
            title: tp('stocks-performance'),
            to: APP_ROUTES.ALERTS_STOCK_PERFORMANCE().config,
          },
          {
            title: tp('stocks-yield-performance'),
            to: APP_ROUTES.ALERTS_STOCK_YIELD().config,
          },
          ...(isSystemAlerts
            ? []
            : [
                {
                  title: tp('alerts-configuration'),
                  to: APP_ROUTES.ALERTS_CONFIGURATION().config,
                },
              ]),

          {
            title: tp('alerts-history'),
            to: APP_ROUTES.ALERTS_HISTORY().config,
          },
        ],
      },

      {
        title: tp('raw-data'),
        Icon: <ContentPasteIcon color={'inherit'} fontSize={'inherit'} />,
        options: [
          {
            title: tp('bonds'),
            to: APP_ROUTES.RAW_DATA_BONDS().config,
          },
          {
            title: tp('stocks'),
            to: APP_ROUTES.RAW_DATA_STOCKS().config,
          },
        ],
      },
      {
        title: tp('reports'),
        Icon: <LibraryBooksIcon color={'inherit'} fontSize={'inherit'} />,
        options: [
          {
            title: tp('yield-and-delta'),
            options: [
              {
                title: tp('yields-index-linked-bonds'),
                to: APP_ROUTES.REPORT_YIELD_LINKED().config,
              },
              { title: tp('yields-shekel'), to: APP_ROUTES.REPORT_YIELD_SHEKEL().config },
              {
                title: tp('delta-index-linked-bonds'),
                to: APP_ROUTES.REPORT_DELTA_LINKED().config,
              },
              { title: tp('delta-shekel'), to: APP_ROUTES.REPORT_DELTA_SHEKEL().config },
            ],
          },
          {
            title: tp('yield-and-delta-market'),
            options: [
              {
                title: tp('yields-linked-market'),
                to: APP_ROUTES.REPORT_YIELD_LINKED_MARKET().config,
              },
              {
                title: tp('yields-shekel-market'),
                to: APP_ROUTES.REPORT_YIELD_SHEKEL_MARKET().config,
              },
              {
                title: tp('delta-linked-market'),
                to: APP_ROUTES.REPORT_DELTA_LINKED_MARKET().config,
              },
              {
                title: tp('delta-shekel-market'),
                to: APP_ROUTES.REPORT_DELTA_SHEKEL_MARKET().config,
              },
            ],
          },
          {
            title: tp('yield-and-delta-gross'),
            options: [
              {
                title: tp('yields-index-linked-bonds'),
                to: APP_ROUTES.REPORT_GROSS_YIELD_LINKED().config,
              },
              { title: tp('yields-shekel'), to: APP_ROUTES.REPORT_GROSS_YIELD_SHEKEL().config },
              {
                title: tp('delta-index-linked-bonds'),
                to: APP_ROUTES.REPORT_GROSS_DELTA_LINKED().config,
              },
              { title: tp('delta-shekel'), to: APP_ROUTES.REPORT_GROSS_DELTA_SHEKEL().config },
            ],
          },
          {
            title: tp('yield-and-delta-gross-market'),
            options: [
              {
                title: tp('yields-linked-gross-market'),
                to: APP_ROUTES.REPORT_GROSS_YIELD_LINKED_MARKET().config,
              },
              {
                title: tp('yields-shekel-gross-market'),
                to: APP_ROUTES.REPORT_GROSS_YIELD_SHEKEL_MARKET().config,
              },
              {
                title: tp('delta-linked-gross-market'),
                to: APP_ROUTES.REPORT_GROSS_DELTA_LINKED_MARKET().config,
              },
              {
                title: tp('delta-shekel-market'),
                to: APP_ROUTES.REPORT_GROSS_DELTA_SHEKEL_MARKET().config,
              },
            ],
          },
          {
            title: tp('yield-extreme'),
            to: APP_ROUTES.REPORT_YIELD_EXTREME().config,
          },
          {
            title: tp('portfolio-summary'),
            to: APP_ROUTES.REPORTS_PORTFOLIO_SUMMARY().config,
          },
        ],
      },
    ];
  }, [tp, isSystemAlerts]);

  const optionsConfigurations = useMemo<NavigationOption[]>(() => {
    return [
      {
        title: tp('users'),
        Icon: <PeopleAltIcon color={'inherit'} fontSize={'inherit'} />,
        options: [
          { title: tp('users-list'), to: APP_ROUTES.USERS().config },
          {
            title: tp('permissions'),
            to: APP_ROUTES.USER_PERMISSIONS().config,
          },
        ],
      },
      {
        title: tp('settings'),
        Icon: <SettingsIcon color={'inherit'} fontSize={'inherit'} />,
        options: [
          {
            title: tp('alerts-portfolio-default'),
            to: APP_ROUTES.ALERTS_PORTFOLIO_DEFAULT().config,
          },
          { title: tp('labels'), to: APP_ROUTES.LABELS().config },
          { title: tp('languages'), to: APP_ROUTES.LANGUAGES().config },
          { title: tp('exclude-dates'), to: APP_ROUTES.EXCLUDE_DATES().config },
          { title: tp('gto-endpoints'), to: APP_ROUTES.GTO_ENDPOINTS().config },
          {
            title: tp('filling-missing-date'),
            to: APP_ROUTES.FILLING_MISSING_DATE().config,
          },
          { title: tp('data-etl'), to: APP_ROUTES.DATA_ETL().config },
          { title: tp('help'), to: APP_ROUTES.HELP().config },
        ],
      },
      {
        title: tp('parameters'),
        Icon: <SettingsApplicationsIcon color={'inherit'} fontSize={'inherit'} />,
        options: [
          { title: tp('issuers'), to: APP_ROUTES.ISSUERS().config },
          { title: tp('branches'), to: APP_ROUTES.BRANCHES().config },
          { title: tp('linked-assets'), to: APP_ROUTES.LINKED_ASSETS().config },
          { title: tp('equity-types'), to: APP_ROUTES.EQUITY_TYPES().config },
          {
            title: tp('trade-statuses'),
            to: APP_ROUTES.TRADE_STATUSES().config,
          },
          {
            title: tp('midroog-ratings'),
            to: APP_ROUTES.MIDROOG_RATINGS().config,
          },
          {
            title: tp('maalot-ratings'),
            to: APP_ROUTES.MAALOT_RATINGS().config,
          },
        ],
      },
      {
        title: tp('archives'),
        Icon: <ArchiveIcon color={'inherit'} fontSize={'inherit'} />,
        options: [
          { title: tp('bonds'), to: APP_ROUTES.ARCHIVE_BONDS().config },
          { title: tp('stocks'), to: APP_ROUTES.ARCHIVE_STOCKS().config },
          {
            title: tp('bonds-raw-data'),
            to: APP_ROUTES.ARCHIVE_BONDS_RAW().config,
          },
          {
            title: tp('stocks-raw-data'),
            to: APP_ROUTES.ARCHIVE_STOCKS_RAW().config,
          },
          {
            title: tp('midroog-ratings'),
            to: APP_ROUTES.ARCHIVE_MIDROOG_RATINGS().config,
          },
          {
            title: tp('maalot-ratings'),
            to: APP_ROUTES.ARCHIVE_MAALOT_RATINGS().config,
          },
          {
            title: tp('users'),
            to: APP_ROUTES.ARCHIVE_USERS().config,
          },
          {
            title: tp('logs'),
            to: APP_ROUTES.ARCHIVE_LOGS().config,
          },
        ],
      },
      {
        title: tp('migrations'),
        Icon: <PublicIcon color={'inherit'} fontSize={'inherit'} />,
        to: APP_ROUTES.MIGRATIONS().config,
      },
    ];
  }, [tp]);

  const optionsLogs = useMemo<NavigationOption[]>(() => {
    return [
      {
        title: tp('system-logs'),
        Icon: <IntegrationInstructionsIcon color={'inherit'} fontSize={'inherit'} />,
        to: APP_ROUTES.LOGS().config,
      },
    ];
  }, [tp]);

  const renderNavigationOptions = (option: NavigationOption, i: number) => {
    return (
      <NavigationItem
        key={i}
        Icon={option.Icon}
        options={option.options}
        title={option.title}
        isMenuOpen={isMenuOpen}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        to={option.to}
      />
    );
  };

  return (
    <NativeScroll mode={'always'} size={'small'} sx={{ overflowX: 'hidden' }}>
      {optionsOverview.map(renderNavigationOptions)}

      <CategoryTitle isOpen={isMenuOpen} pt={'2rem'} pb={'1rem'}>
        {tp('data-information')}
      </CategoryTitle>
      {optionsDataInformation.map(renderNavigationOptions)}

      <CategoryTitle isOpen={isMenuOpen} pt={'2rem'} pb={'1rem'}>
        {tp('configurations')}
      </CategoryTitle>
      {optionsConfigurations.map(renderNavigationOptions)}

      <CategoryTitle isOpen={isMenuOpen} pt={'2rem'} pb={'1rem'}>
        {tp('logs')}
      </CategoryTitle>
      {optionsLogs.map(renderNavigationOptions)}
    </NativeScroll>
  );
};
