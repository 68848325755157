import { createAsyncThunk } from '@reduxjs/toolkit';
import { ILinkAssetType } from '__generated__/api';
import { IBondGelemMarketReport, SELECT_BOND_GELEM_MARKET_REPORT } from 'services/bonds-helper';
import { multiplyBondRowItems } from 'utils/bonds';
import { dateFormat } from 'utils/dates';
import { API, parseErrorData } from 'utils/service';
import { AppAsyncThunkConfig } from '../index';
import { PREFIX } from './helpers';
import { selectorsReportDeltaLinkedMarket } from './selectors';

export const actionReportDeltaLinkedMarketFetch = createAsyncThunk<
  Array<IBondGelemMarketReport>,
  void | { initDate: string },
  AppAsyncThunkConfig
>(`${PREFIX}/actionReportDeltaLinkedMarketFetch`, async (_, { getState }) => {
  try {
    const { date } = selectorsReportDeltaLinkedMarket.filters(getState());
    const { data } = await API.api.bondsHelperGetGelemBondMarketItemsDynamicCreate({
      select: SELECT_BOND_GELEM_MARKET_REPORT,
      date: dateFormat(date, 'yyyy-MM-dd'),
      linkAssetType: ILinkAssetType.CPI,
    });

    return data.items.map(multiplyBondRowItems) as unknown as Array<IBondGelemMarketReport>;
  } catch (e: unknown) {
    throw parseErrorData(e);
  }
});
