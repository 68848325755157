import { createAsyncThunk } from '@reduxjs/toolkit';
import { ILinkAssetType } from '__generated__/api';
import { IBondGrossMarketReport, SELECT_BOND_GROSS_MARKET_REPORT } from 'services/bonds-helper';
import { multiplyBondRowItems } from 'utils/bonds';
import { dateFormat } from 'utils/dates';
import * as dynamic from 'utils/dynamic';
import { API, parseErrorData } from 'utils/service';
import { AppAsyncThunkConfig } from '../index';
import { PREFIX } from './helpers';
import { selectorsReportGrossYieldShekelMarket } from './selectors';

export const actionReportGrossYieldShekelMarketFetch = createAsyncThunk<
  Array<IBondGrossMarketReport>,
  void,
  AppAsyncThunkConfig
>(`${PREFIX}/actionReportGrossYieldShekelMarketFetch`, async (_, { getState }) => {
  try {
    const { date, grossDuration, value } =
      selectorsReportGrossYieldShekelMarket.filters(getState());
    const { data } = await API.api.bondsHelperGetGrossYieldMarketPanelItemsDynamicCreate({
      select: SELECT_BOND_GROSS_MARKET_REPORT,
      filter: dynamic
        .mergeFilters(
          dynamic.makeFilter(
            'grossYieldToMaturity',
            value[0] ?? -1000,
            dynamic.decoratorDivided(dynamic.moreOrEquals),
          ),
          dynamic.makeFilter(
            'grossYieldToMaturity',
            value[1] ?? 1000,
            dynamic.decoratorDivided(dynamic.lessOrEquals),
          ),
        )
        .join('&&'),
      minDate: dateFormat(date[0], 'yyyy-MM-01'),
      maxDate: dateFormat(date[1], 'yyyy-MM-01'),
      minGrossDuration: Number(grossDuration[0] ?? -1000),
      maxGrossDuration: Number(grossDuration[1] ?? 1000),
      linkAssetType: ILinkAssetType.ILS,
    });

    return data.items.map(multiplyBondRowItems) as unknown as Array<IBondGrossMarketReport>;
  } catch (e: unknown) {
    throw parseErrorData(e);
  }
});
