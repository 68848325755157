/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface IAppLog {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  /** @format int32 */
  logTypeID?: number | null;
  typeID?: string | null;
  sourceID?: string | null;
  reflectionMetaData?: string | null;
  message?: string | null;
  messageDetails?: string | null;
  appUserID?: string | null;
  logType?: ILogType;
  logSource?: ILogSource;
}

export interface IAppLogArchive {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  logTypeID?: number;
  /** @format date-time */
  dateAndTime?: string;
  /**
   * @minLength 0
   * @maxLength 250
   */
  status?: string | null;
  /**
   * @minLength 0
   * @maxLength 4000
   */
  message?: string | null;
}

export interface IAppLogArchiveDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IAppLogArchive[];
}

export interface IAppLogDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IAppLog[];
}

export interface IBond {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  /** @format int32 */
  symbol?: number;
  name?: string | null;
  issuerID?: string | null;
  linkedAssetID?: string | null;
  branchID?: string | null;
  equityTypeID?: string | null;
  midroogRatingID?: string | null;
  maalotRatingID?: string | null;
  issuerNum?: string | null;
  issuerName?: string | null;
  controllingShareHolder?: string | null;
  equityTypeName?: string | null;
  branchName?: string | null;
  linkedAssetName?: string | null;
  /** @format float */
  baseRate?: number | null;
  /** @format float */
  lastDealRate?: number | null;
  /** @format float */
  baseRateChangePercentage?: number | null;
  /** @format float */
  yesterdayYield?: number | null;
  /** @format float */
  beginOfYearYield?: number | null;
  /** @format float */
  grossYieldToMaturity?: number | null;
  /** @format float */
  grossDuration?: number | null;
  /** @format float */
  grossYieldToMaturityDeltaByDuration?: number | null;
  bondMidroogRank?: string | null;
  bondMidroogOutlook?: string | null;
  bondMaalotOutlook?: string | null;
  bondMaalotRank?: string | null;
  tradingStage?: string | null;
  /** @format float */
  weekAverageNIS?: number | null;
  /** @format float */
  dailyTurnover?: number | null;
  /** @format float */
  annualInterest?: number | null;
  /** @format float */
  grossAdjustedValue?: number | null;
  /** @format float */
  listedFortune?: number | null;
  /** @format date-time */
  redemptionOrExpirationDate?: string | null;
  /** @format date-time */
  lastRateDate?: string | null;
  /** @format date-time */
  issuingDate?: string | null;
  /** @format float */
  flowYear1?: number | null;
  /** @format float */
  flowYear2?: number | null;
  /** @format float */
  flowYear3?: number | null;
  /** @format float */
  flowYear4?: number | null;
  /** @format float */
  flowYear5?: number | null;
  /** @format float */
  flowYear6?: number | null;
  /** @format float */
  flowYear7?: number | null;
  /** @format float */
  flowYearElse?: number | null;
  collateralSummary?: string | null;
  countryOfIncorporation?: string | null;
  /** @format float */
  calcSynDiffAvgWeek?: number | null;
  /** @format float */
  calcSynDiffAvgMonth?: number | null;
  /** @format float */
  calcSynDiffAvgYear?: number | null;
  /** @format float */
  calcSynDiffAvgWeekMove?: number | null;
  /** @format float */
  calcSynDiffAvgMonthMove?: number | null;
  /** @format float */
  calcSynDiffAvgYearMove?: number | null;
  /** @format float */
  calcSynDiffSDYear?: number | null;
  /** @format float */
  calcSynDiffAvgYearAbsolute?: number | null;
  /** @format float */
  calcSynDiffMoovers?: number | null;
  /** @format float */
  calcYieldBrutoAvgWeek?: number | null;
  /** @format float */
  calcYieldBrutoAvgMonth?: number | null;
  /** @format float */
  calcYieldBrutoAvgYear?: number | null;
  /** @format float */
  calcYieldBrutoAvgWeekMove?: number | null;
  /** @format float */
  calcYieldBrutoAvgMonthMove?: number | null;
  /** @format float */
  calcYieldBrutoAvgYearMove?: number | null;
  /** @format float */
  calcYieldBrutoSDYear?: number | null;
  /** @format float */
  calcYieldBrutoMoovers?: number | null;
  /** @format float */
  viewYieldBrutoAvgWeek?: number | null;
  /** @format float */
  viewYieldBrutoAvgMonth?: number | null;
  /** @format float */
  viewYieldBrutoAvgYear?: number | null;
  /** @format float */
  viewYieldBrutoLastDay?: number | null;
  /** @format float */
  viewSynDiffLastDay?: number | null;
  /** @format float */
  viewYieldBrutoMinusLinkedAssetCurrent?: number | null;
  /** @format float */
  viewYieldBrutoMinusLinkedAssetAvgWeek?: number | null;
  /** @format float */
  viewYieldBrutoMinusLinkedAssetAvgMonth?: number | null;
  /** @format float */
  viewYieldBrutoMinusLinkedAssetAvgYear?: number | null;
  /** @format int32 */
  viewMidroogRatingRank?: number | null;
  /** @format int32 */
  viewMaalotRatingRank?: number | null;
  midroogRating?: IMidroogRating;
  maalotRating?: IMaalotRating;
  issuer?: IIssuer;
  equityType?: IEquityType;
  branch?: IBranch;
  linkedAsset?: ILinkedAsset;
  userProfileBonds?: IUserProfileBond[] | null;
}

export interface IBondArchive {
  /** @format int32 */
  symbol?: number;
  /** @format date-time */
  startdate?: string | null;
  /** @format date-time */
  updatetime?: string | null;
  /** @format float */
  issuer_num?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  controlling_group?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  issuer?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  sector_tase?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  industry?: string | null;
  /** @format float */
  base?: number | null;
  /** @format float */
  last?: number | null;
  /** @format float */
  percentchange?: number | null;
  /** @format float */
  prev_trade_pcg?: number | null;
  /** @format float */
  yield_ytd?: number | null;
  /** @format float */
  yield_bruto?: number | null;
  /** @format float */
  duration_bruto?: number | null;
  /** @format float */
  syn_diff?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  linked_asset?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  midroog_rating?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  midroog_outlook?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  maalot_rating?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  maalot_outlook?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  issuer_rating_maalot?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  issuer_rating_midroog?: string | null;
  /** @format float */
  coupon_annual?: number | null;
  /** @format float */
  adjusted_value_bruto?: number | null;
  /** @format float */
  average_deal_sise?: number | null;
  /** @format float */
  average_volume_week?: number | null;
  /** @format float */
  volume?: number | null;
  /** @format float */
  listed_capital?: number | null;
  /** @format date-time */
  expiration_date?: string | null;
  /** @format date-time */
  ipo_date?: string | null;
  /** @format date-time */
  last_rate_date?: string | null;
  /** @format float */
  flow_year1?: number | null;
  /** @format float */
  flow_year2?: number | null;
  /** @format float */
  flow_year3?: number | null;
  /** @format float */
  flow_year4?: number | null;
  /** @format float */
  flow_year5?: number | null;
  /** @format float */
  flow_year6?: number | null;
  /** @format float */
  flow_year7?: number | null;
  /** @format float */
  flow_year_else?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  trade_status_rezef?: string | null;
  /** @format float */
  calc_syn_diff_avg_week?: number | null;
  /** @format float */
  calc_syn_diff_avg_month?: number | null;
  /** @format float */
  calc_syn_diff_avg_year?: number | null;
  /** @format float */
  calc_syn_diff_avg_week_move?: number | null;
  /** @format float */
  calc_syn_diff_avg_month_move?: number | null;
  /** @format float */
  calc_syn_diff_avg_year_move?: number | null;
  /** @format float */
  calc_syn_diff_sd_year?: number | null;
  /** @format float */
  calc_syn_diff_avg_year_absolute?: number | null;
  /** @format float */
  calc_syn_diff_moovers?: number | null;
  /** @format float */
  calc_yield_bruto_avg_week?: number | null;
  /** @format float */
  calc_yield_bruto_avg_month?: number | null;
  /** @format float */
  calc_yield_bruto_avg_year?: number | null;
  /** @format float */
  calc_yield_bruto_avg_week_move?: number | null;
  /** @format float */
  calc_yield_bruto_avg_month_move?: number | null;
  /** @format float */
  calc_yield_bruto_avg_year_move?: number | null;
  /** @format float */
  calc_yield_bruto_sd_year?: number | null;
  /** @format float */
  calc_yield_bruto_moovers?: number | null;
  /** @format float */
  view_yield_bruto_avg_week?: number | null;
  /** @format float */
  view_yield_bruto_avg_month?: number | null;
  /** @format float */
  view_yield_bruto_avg_year?: number | null;
  /** @format float */
  view_yield_bruto_last_day?: number | null;
  /** @format float */
  view_syn_diff_last_day?: number | null;
  /** @format float */
  view_yield_bruto_minus_linked_asset_current?: number | null;
  /** @format float */
  view_yield_bruto_minus_linked_asset_avg_week?: number | null;
  /** @format float */
  view_yield_bruto_minus_linked_asset_avg_month?: number | null;
  /** @format float */
  view_yield_bruto_minus_linked_asset_avg_year?: number | null;
  /** @format int32 */
  view_midroog_rating?: number | null;
  /** @format int32 */
  view_maalot_rating?: number | null;
}

export interface IBondArchiveDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IBondArchive[];
}

export interface IBondDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IBond[];
}

export interface IBondGelem {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  /** @format int32 */
  dateDay?: number;
  /** @format int32 */
  dateMonth?: number;
  /** @format int32 */
  dateYear?: number;
  /** @format date-time */
  lastDealDate?: string;
  lastDealTime?: string | null;
  lastDealDateTime?: string | null;
  lastRateTime?: string | null;
  /** @format date-time */
  lastKnownRateDate?: string | null;
  /** @format int32 */
  symbol?: number;
  name?: string | null;
  issuerNum?: string | null;
  issuerName?: string | null;
  controllingShareHolder?: string | null;
  equityTypeName?: string | null;
  branchName?: string | null;
  baseRate?: string | null;
  lastDealRate?: string | null;
  baseRateChangePercentage?: string | null;
  yesterdayYield?: string | null;
  beginOfYearYield?: string | null;
  grossYieldToMaturity?: string | null;
  grossDuration?: string | null;
  grossYieldToMaturityDeltaByDuration?: string | null;
  linkedAssetName?: string | null;
  bondMidroogRank?: string | null;
  bondMidroogOutlook?: string | null;
  bondMaalotOutlook?: string | null;
  bondMaalotRank?: string | null;
  issuerRatingMaalot?: string | null;
  issuerRatingMidroog?: string | null;
  weekAverageNIS?: string | null;
  averageDealSize?: string | null;
  dailyTurnover?: string | null;
  annualInterest?: string | null;
  grossAdjustedValue?: string | null;
  listedFortune?: string | null;
  redemptionOrExpirationDate?: string | null;
  issuingDate?: string | null;
  lastRateDate?: string | null;
  flowYear1?: string | null;
  flowYear2?: string | null;
  flowYear3?: string | null;
  flowYear4?: string | null;
  flowYear5?: string | null;
  flowYear6?: string | null;
  flowYear7?: string | null;
  flowYearElse?: string | null;
  tradingStage?: string | null;
  collateralSummary?: string | null;
  countryOfIncorporation?: string | null;
}

export interface IBondGelemArchive {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  date_day?: number;
  /** @format int32 */
  date_month?: number;
  /** @format int32 */
  date_year?: number;
  /** @format date-time */
  updatedate?: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  updatetime?: string | null;
  /** @format int32 */
  symbol?: number;
  /**
   * @minLength 0
   * @maxLength 255
   */
  issuer_num?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  controlling_group?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  issuer?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  sector_tase?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  industry?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  base?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  last?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  percentchange?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  prev_trade_pcg?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  yield_ytd?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  yield_bruto?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  duration_bruto?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  syn_diff?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  linked_asset?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  midroog_rating?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  midroog_outlook?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  maalot_rating?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  maalot_outlook?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  issuer_rating_maalot?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  issuer_rating_midroog?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  coupon_annual?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  adjusted_value_bruto?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  average_deal_sise?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  average_volume_week?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  volume?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  listed_capital?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  expiration_date?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  ipo_date?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  last_rate_date?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  flow_year1?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  flow_year2?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  flow_year3?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  flow_year4?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  flow_year5?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  flow_year6?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  flow_year7?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  flow_year_else?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  trade_status_rezef?: string | null;
}

export interface IBondGelemArchiveDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IBondGelemArchive[];
}

export interface IBondGelemDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IBondGelem[];
}

export interface IBondOnline {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  /** @format int32 */
  symbol?: number;
  name?: string | null;
  issuerID?: string | null;
  linkedAssetID?: string | null;
  branchID?: string | null;
  equityTypeID?: string | null;
  midroogRatingID?: string | null;
  maalotRatingID?: string | null;
  issuerNum?: string | null;
  issuerName?: string | null;
  controllingShareHolder?: string | null;
  equityTypeName?: string | null;
  branchName?: string | null;
  linkedAssetName?: string | null;
  /** @format float */
  baseRate?: number | null;
  /** @format float */
  lastDealRate?: number | null;
  /** @format float */
  baseRateChangePercentage?: number | null;
  /** @format float */
  yesterdayYield?: number | null;
  /** @format float */
  beginOfYearYield?: number | null;
  /** @format float */
  grossYieldToMaturity?: number | null;
  /** @format float */
  grossDuration?: number | null;
  /** @format float */
  grossYieldToMaturityDeltaByDuration?: number | null;
  bondMidroogRank?: string | null;
  bondMidroogOutlook?: string | null;
  bondMaalotOutlook?: string | null;
  bondMaalotRank?: string | null;
  tradingStage?: string | null;
  /** @format float */
  weekAverageNIS?: number | null;
  /** @format float */
  dailyTurnover?: number | null;
  /** @format float */
  annualInterest?: number | null;
  /** @format float */
  grossAdjustedValue?: number | null;
  /** @format float */
  listedFortune?: number | null;
  /** @format date-time */
  redemptionOrExpirationDate?: string | null;
  /** @format date-time */
  lastRateDate?: string | null;
  /** @format date-time */
  issuingDate?: string | null;
  /** @format float */
  flowYear1?: number | null;
  /** @format float */
  flowYear2?: number | null;
  /** @format float */
  flowYear3?: number | null;
  /** @format float */
  flowYear4?: number | null;
  /** @format float */
  flowYear5?: number | null;
  /** @format float */
  flowYear6?: number | null;
  /** @format float */
  flowYear7?: number | null;
  /** @format float */
  flowYearElse?: number | null;
  collateralSummary?: string | null;
  countryOfIncorporation?: string | null;
  /** @format float */
  calcSynDiffAvgWeek?: number | null;
  /** @format float */
  calcSynDiffAvgMonth?: number | null;
  /** @format float */
  calcSynDiffAvgYear?: number | null;
  /** @format float */
  calcSynDiffAvgWeekMove?: number | null;
  /** @format float */
  calcSynDiffAvgMonthMove?: number | null;
  /** @format float */
  calcSynDiffAvgYearMove?: number | null;
  /** @format float */
  calcSynDiffSDYear?: number | null;
  /** @format float */
  calcSynDiffAvgYearAbsolute?: number | null;
  /** @format float */
  calcSynDiffMoovers?: number | null;
  /** @format float */
  calcYieldBrutoAvgWeek?: number | null;
  /** @format float */
  calcYieldBrutoAvgMonth?: number | null;
  /** @format float */
  calcYieldBrutoAvgYear?: number | null;
  /** @format float */
  calcYieldBrutoAvgWeekMove?: number | null;
  /** @format float */
  calcYieldBrutoAvgMonthMove?: number | null;
  /** @format float */
  calcYieldBrutoAvgYearMove?: number | null;
  /** @format float */
  calcYieldBrutoSDYear?: number | null;
  /** @format float */
  calcYieldBrutoMoovers?: number | null;
  /** @format float */
  viewYieldBrutoAvgWeek?: number | null;
  /** @format float */
  viewYieldBrutoAvgMonth?: number | null;
  /** @format float */
  viewYieldBrutoAvgYear?: number | null;
  /** @format float */
  viewYieldBrutoLastDay?: number | null;
  /** @format float */
  viewSynDiffLastDay?: number | null;
  /** @format float */
  viewYieldBrutoMinusLinkedAssetCurrent?: number | null;
  /** @format float */
  viewYieldBrutoMinusLinkedAssetAvgWeek?: number | null;
  /** @format float */
  viewYieldBrutoMinusLinkedAssetAvgMonth?: number | null;
  /** @format float */
  viewYieldBrutoMinusLinkedAssetAvgYear?: number | null;
  /** @format int32 */
  viewMidroogRatingRank?: number | null;
  /** @format int32 */
  viewMaalotRatingRank?: number | null;
  midroogRating?: IMidroogRating;
  maalotRating?: IMaalotRating;
  issuer?: IIssuer;
  equityType?: IEquityType;
  branch?: IBranch;
  linkedAsset?: ILinkedAsset;
}

export interface IBondOnlineDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IBondOnline[];
}

export interface IBranch {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  title?: string | null;
  externalTitle?: string | null;
  bonds?: IBond[] | null;
  bondOnlines?: IBondOnline[] | null;
  stocks?: IStock[] | null;
  stockOnlines?: IStockOnline[] | null;
}

export interface IBranchDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IBranch[];
}

export interface ICloudFileViewModel {
  fileName?: string | null;
  fileStreamString?: string | null;
  filePath?: string | null;
  isImage?: boolean;
}

export interface ICurrentUserProfileResponse {
  appUserID?: string | null;
  languageID?: string | null;
  name?: string | null;
  email?: string | null;
  userPhoto?: string | null;
  userProfilePermission?: IUserProfilePermission;
  isActive?: boolean;
  isSystemAlerts?: boolean;
}

export interface IDataBond {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  /** @format int32 */
  symbol?: number;
  name?: string | null;
  issuerID?: string | null;
  linkedAssetID?: string | null;
  branchID?: string | null;
  equityTypeID?: string | null;
  midroogRatingID?: string | null;
  maalotRatingID?: string | null;
  issuerNum?: string | null;
  issuerName?: string | null;
  controllingShareHolder?: string | null;
  equityTypeName?: string | null;
  branchName?: string | null;
  linkedAssetName?: string | null;
  /** @format float */
  baseRate?: number | null;
  /** @format float */
  lastDealRate?: number | null;
  /** @format float */
  baseRateChangePercentage?: number | null;
  /** @format float */
  yesterdayYield?: number | null;
  /** @format float */
  beginOfYearYield?: number | null;
  /** @format float */
  grossYieldToMaturity?: number | null;
  /** @format float */
  grossDuration?: number | null;
  /** @format float */
  grossYieldToMaturityDeltaByDuration?: number | null;
  bondMidroogRank?: string | null;
  bondMidroogOutlook?: string | null;
  bondMaalotOutlook?: string | null;
  bondMaalotRank?: string | null;
  tradingStage?: string | null;
  /** @format float */
  weekAverageNIS?: number | null;
  /** @format float */
  dailyTurnover?: number | null;
  /** @format float */
  annualInterest?: number | null;
  /** @format float */
  grossAdjustedValue?: number | null;
  /** @format float */
  listedFortune?: number | null;
  /** @format date-time */
  redemptionOrExpirationDate?: string | null;
  /** @format date-time */
  lastRateDate?: string | null;
  /** @format date-time */
  issuingDate?: string | null;
  /** @format float */
  flowYear1?: number | null;
  /** @format float */
  flowYear2?: number | null;
  /** @format float */
  flowYear3?: number | null;
  /** @format float */
  flowYear4?: number | null;
  /** @format float */
  flowYear5?: number | null;
  /** @format float */
  flowYear6?: number | null;
  /** @format float */
  flowYear7?: number | null;
  /** @format float */
  flowYearElse?: number | null;
  /** @format float */
  calcSynDiffAvgWeek?: number | null;
  /** @format float */
  calcSynDiffAvgMonth?: number | null;
  /** @format float */
  calcSynDiffAvgYear?: number | null;
  /** @format float */
  calcSynDiffAvgWeekMove?: number | null;
  /** @format float */
  calcSynDiffAvgMonthMove?: number | null;
  /** @format float */
  calcSynDiffAvgYearMove?: number | null;
  /** @format float */
  calcSynDiffSDYear?: number | null;
  /** @format float */
  calcSynDiffAvgYearAbsolute?: number | null;
  /** @format float */
  calcSynDiffMoovers?: number | null;
  /** @format float */
  calcYieldBrutoAvgWeek?: number | null;
  /** @format float */
  calcYieldBrutoAvgMonth?: number | null;
  /** @format float */
  calcYieldBrutoAvgYear?: number | null;
  /** @format float */
  calcYieldBrutoAvgWeekMove?: number | null;
  /** @format float */
  calcYieldBrutoAvgMonthMove?: number | null;
  /** @format float */
  calcYieldBrutoAvgYearMove?: number | null;
  /** @format float */
  calcYieldBrutoSDYear?: number | null;
  /** @format float */
  calcYieldBrutoMoovers?: number | null;
  /** @format float */
  viewYieldBrutoAvgWeek?: number | null;
  /** @format float */
  viewYieldBrutoAvgMonth?: number | null;
  /** @format float */
  viewYieldBrutoAvgYear?: number | null;
  /** @format float */
  viewYieldBrutoLastDay?: number | null;
  /** @format float */
  viewSynDiffLastDay?: number | null;
  /** @format float */
  viewYieldBrutoMinusLinkedAssetCurrent?: number | null;
  /** @format float */
  viewYieldBrutoMinusLinkedAssetAvgWeek?: number | null;
  /** @format float */
  viewYieldBrutoMinusLinkedAssetAvgMonth?: number | null;
  /** @format float */
  viewYieldBrutoMinusLinkedAssetAvgYear?: number | null;
  /** @format int32 */
  viewMidroogRatingRank?: number | null;
  /** @format int32 */
  viewMaalotRatingRank?: number | null;
}

export interface IDataBondDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IDataBond[];
}

export interface IDataBondStatItem {
  /** @format int32 */
  rankOrder?: number;
  rank?: string | null;
  /** @format double */
  avarage?: number;
  /** @format double */
  standardDeviation?: number;
  /** @format double */
  median?: number;
  /** @format double */
  maximum?: number;
  /** @format double */
  minimum?: number;
  /** @format int32 */
  numberOfBonds?: number;
}

export interface IDataETL {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  logSourceID?: string | null;
  userProfileID?: string | null;
  /** @format date-time */
  entryDate?: string;
  finished?: boolean;
  userProfile?: IUserProfile;
  logSource?: ILogSource;
}

export interface IDataETLDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IDataETL[];
}

export interface IDataGelemBond {
  id?: string | null;
  /** @format date-time */
  lastKnownRateDate?: string;
  /** @format date-time */
  redemptionOrExpirationDate?: string | null;
  /** @format date-time */
  issuingDate?: string | null;
  /** @format date-time */
  lastRateDate?: string | null;
  /** @format int32 */
  symbol?: number;
  name?: string | null;
  linkedAssetName?: string | null;
  issuerNum?: string | null;
  issuerName?: string | null;
  controllingShareHolder?: string | null;
  equityTypeName?: string | null;
  branchName?: string | null;
  /** @format float */
  baseRate?: number | null;
  /** @format float */
  lastDealRate?: number | null;
  /** @format float */
  grossDuration?: number | null;
  /** @format float */
  grossAdjustedValue?: number | null;
  ratingGroup?: string | null;
  bondMidroogRank?: string | null;
  /** @format int32 */
  viewMidroogRatingRank?: number;
  bondMidroogOutlook?: string | null;
  maalotRatingGroup?: string | null;
  bondMaalotRank?: string | null;
  /** @format int32 */
  viewMaalotRatingRank?: number;
  bondMaalotOutlook?: string | null;
  combineRating?: string | null;
  combineRatingGroup?: string | null;
  countryOfIncorporation?: string | null;
  /** @format float */
  baseRateChangePercentage?: number | null;
  /** @format float */
  yesterdayYield?: number | null;
  /** @format float */
  beginOfYearYield?: number | null;
  /** @format float */
  grossYieldToMaturity?: number | null;
  /** @format float */
  grossYieldToMaturityDeltaByDuration?: number | null;
  /** @format float */
  flowYear1?: number | null;
  /** @format float */
  flowYear2?: number | null;
  /** @format float */
  flowYear3?: number | null;
  /** @format float */
  flowYear4?: number | null;
  /** @format float */
  flowYear5?: number | null;
  /** @format float */
  flowYear6?: number | null;
  /** @format float */
  flowYear7?: number | null;
  /** @format float */
  flowYearElse?: number | null;
}

export interface IDataGelemBondDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IDataGelemBond[];
}

export interface IDataGelemMarketBond {
  id?: string | null;
  /** @format date-time */
  lastRateDate?: string | null;
  /** @format int32 */
  symbol?: number;
  name?: string | null;
  linkedAssetName?: string | null;
  issuerNum?: string | null;
  issuerName?: string | null;
  equityTypeName?: string | null;
  branchName?: string | null;
  /** @format float */
  grossDuration?: number | null;
  midroogRating?: string | null;
  maalotRating?: string | null;
  marketRating?: string | null;
  /** @format int32 */
  ratingRank?: number;
  ratingGroup?: string | null;
  /** @format int32 */
  ratingGroupRank?: number;
  /** @format float */
  grossYieldToMaturity?: number | null;
  /** @format float */
  grossYieldToMaturityDeltaByDuration?: number | null;
}

export interface IDataGelemMarketBondDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IDataGelemMarketBond[];
}

export interface IDataGrossYieldMarketPanelItem {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  /** @format date-time */
  lastRateDate?: string | null;
  /** @format int32 */
  year?: number | null;
  /** @format int32 */
  month?: number | null;
  yearMonth?: string | null;
  /** @format int32 */
  symbol?: number;
  name?: string | null;
  linkedAssetName?: string | null;
  /** @format int32 */
  linkedAssetType?: number;
  issuerNum?: string | null;
  issuerName?: string | null;
  equityTypeName?: string | null;
  branchName?: string | null;
  ratingGroup?: string | null;
  /** @format int32 */
  ratingGroupRank?: number | null;
  midroogRating?: string | null;
  /** @format int32 */
  ratingRank?: number | null;
  maalotRating?: string | null;
  marketRating?: string | null;
  /** @format float */
  grossYieldToMaturity?: number | null;
  /** @format float */
  grossDuration?: number | null;
  /** @format float */
  grossYieldToMaturityDeltaByDuration?: number | null;
}

export interface IDataGrossYieldMarketPanelItemDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IDataGrossYieldMarketPanelItem[];
}

export interface IDataGrossYieldPanelItem {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  /** @format date-time */
  lastRateDate?: string | null;
  /** @format int32 */
  year?: number | null;
  /** @format int32 */
  month?: number | null;
  yearMonth?: string | null;
  /** @format int32 */
  symbol?: number;
  name?: string | null;
  linkedAssetName?: string | null;
  /** @format int32 */
  linkedAssetType?: number;
  issuerNum?: string | null;
  issuerName?: string | null;
  equityTypeName?: string | null;
  branchName?: string | null;
  midroogRatingGroup?: string | null;
  /** @format int32 */
  midroogRatingGroupRank?: number | null;
  midroogRating?: string | null;
  /** @format int32 */
  ratingRank?: number | null;
  maalotRating?: string | null;
  /** @format float */
  grossYieldToMaturity?: number | null;
  /** @format float */
  grossDuration?: number | null;
  /** @format float */
  grossYieldToMaturityDeltaByDuration?: number | null;
}

export interface IDataGrossYieldPanelItemDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IDataGrossYieldPanelItem[];
}

export interface IDataGrossYieldToMaturityDeltaByDurationMarketStat {
  id?: string | null;
  yearMonth?: string | null;
  rating?: string | null;
  /** @format int32 */
  rank?: number;
  /** @format int32 */
  total?: number;
  /** @format float */
  grossYieldToMaturityDeltaByDuration?: number;
}

export interface IDataGrossYieldToMaturityDeltaByDurationStat {
  id?: string | null;
  yearMonth?: string | null;
  rating?: string | null;
  /** @format int32 */
  rank?: number;
  /** @format int32 */
  total?: number;
  /** @format float */
  grossYieldToMaturityDeltaByDuration?: number;
}

export interface IDataGrossYieldToMaturityMarketStat {
  id?: string | null;
  yearMonth?: string | null;
  rating?: string | null;
  /** @format int32 */
  rank?: number;
  /** @format int32 */
  total?: number;
  /** @format float */
  grossYieldToMaturity?: number;
}

export interface IDataGrossYieldToMaturityStat {
  id?: string | null;
  yearMonth?: string | null;
  rating?: string | null;
  /** @format int32 */
  rank?: number;
  /** @format int32 */
  total?: number;
  /** @format float */
  grossYieldToMaturity?: number;
}

export interface IDataMissingDate {
  /** @format date-time */
  missingDate?: string;
}

export interface IDataStock {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  /** @format int32 */
  symbol?: number;
  name?: string | null;
  issuerID?: string | null;
  branchID?: string | null;
  equityTypeID?: string | null;
  tradeStatusID?: string | null;
  viewMidroogRatingMinID?: string | null;
  viewMidroogRatingMaxID?: string | null;
  issuerNum?: string | null;
  issuerName?: string | null;
  controllingShareHolder?: string | null;
  equityTypeName?: string | null;
  branchName?: string | null;
  tradeStatusName?: string | null;
  tradingStage?: string | null;
  /** @format float */
  baseRate?: number | null;
  /** @format float */
  lastDealRate?: number | null;
  /** @format float */
  baseRateChangePercentage?: number | null;
  /** @format float */
  allYearYield?: number | null;
  /** @format float */
  weekYield?: number | null;
  /** @format float */
  beginOfYearYield?: number | null;
  /** @format float */
  allMonthYield?: number | null;
  /** @format float */
  peRetio?: number | null;
  /** @format float */
  currentMarketWorth?: number | null;
  /** @format float */
  weekAverageTurnover?: number | null;
  /** @format float */
  dailyTurnover?: number | null;
  /** @format float */
  listedFortune?: number | null;
  /** @format float */
  dividendYield?: number | null;
  /** @format float */
  pbRatio?: number | null;
  /** @format float */
  yesterdayYield?: number | null;
  /** @format date-time */
  lastRateDate?: string | null;
  /** @format date-time */
  issuingDate?: string | null;
  /** @format float */
  calcPercentchangeMinusRf?: number | null;
  /** @format float */
  calcPercentchangeMinusRfAvg?: number | null;
  /** @format float */
  calcPercentchangeMinusRfSD?: number | null;
  /** @format float */
  calcReturnMinusTAIndexWeek?: number | null;
  /** @format float */
  calcReturnMinusTAIndexMonth?: number | null;
  /** @format float */
  calcReturnMinusTAIndexYear?: number | null;
  /** @format float */
  calcPercentchangeAvgYear?: number | null;
  /** @format float */
  calcPercentchangeSDYear?: number | null;
  /** @format int32 */
  viewNumberOfBonds?: number | null;
  /** @format float */
  calcYield7TA125?: number | null;
  /** @format float */
  calcYield30TA125?: number | null;
  /** @format float */
  calcYield365TA125?: number | null;
}

export interface IDataStockDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IDataStock[];
}

export interface IDataUserBond {
  bondID?: string | null;
  /** @format int32 */
  symbol?: number;
  name?: string | null;
  issuerID?: string | null;
  linkedAssetID?: string | null;
  equityTypeID?: string | null;
  midroogRatingID?: string | null;
  maalotRatingID?: string | null;
  branchID?: string | null;
  issuerNum?: string | null;
  issuerName?: string | null;
  equityTypeName?: string | null;
  linkedAssetName?: string | null;
  branchName?: string | null;
  /** @format float */
  grossYieldToMaturity?: number | null;
  /** @format float */
  grossDuration?: number | null;
  /** @format float */
  grossYieldToMaturityDeltaByDuration?: number | null;
  bondMidroogRank?: string | null;
  bondMidroogOutlook?: string | null;
  bondMaalotOutlook?: string | null;
  bondMaalotRank?: string | null;
  tradingStage?: string | null;
  /** @format date-time */
  lastRateDate?: string | null;
  /** @format float */
  dailyTurnover?: number | null;
  /** @format float */
  weekAverageNIS?: number | null;
  /** @format float */
  calcSynDiffAvgMonth?: number | null;
  /** @format float */
  calcSynDiffAvgMonthMove?: number | null;
  /** @format float */
  calcSynDiffAvgWeek?: number | null;
  /** @format float */
  calcSynDiffAvgWeekMove?: number | null;
  /** @format float */
  calcSynDiffAvgYear?: number | null;
  /** @format float */
  calcSynDiffAvgYearAbsolute?: number | null;
  /** @format float */
  calcSynDiffAvgYearMove?: number | null;
  /** @format float */
  calcSynDiffSDYear?: number | null;
  /** @format float */
  calcYieldBrutoAvgMonth?: number | null;
  /** @format float */
  calcYieldBrutoAvgMonthMove?: number | null;
  /** @format float */
  calcYieldBrutoAvgWeek?: number | null;
  /** @format float */
  calcYieldBrutoAvgWeekMove?: number | null;
  /** @format float */
  calcYieldBrutoAvgYear?: number | null;
  /** @format float */
  calcYieldBrutoAvgYearMove?: number | null;
  /** @format float */
  calcYieldBrutoSDYear?: number | null;
  /** @format float */
  viewYieldBrutoAvgMonth?: number | null;
  /** @format float */
  viewYieldBrutoAvgWeek?: number | null;
  /** @format float */
  viewYieldBrutoAvgYear?: number | null;
  /** @format float */
  viewYieldBrutoMinusLinkedAssetCurrent?: number | null;
  /** @format float */
  viewYieldBrutoMinusLinkedAssetAvgMonth?: number | null;
  /** @format float */
  viewYieldBrutoMinusLinkedAssetAvgWeek?: number | null;
  /** @format float */
  viewYieldBrutoMinusLinkedAssetAvgYear?: number | null;
  /** @format float */
  calcYieldBrutoMoovers?: number | null;
  /** @format float */
  calcSynDiffMoovers?: number | null;
  /** @format float */
  viewYieldBrutoLastDay?: number | null;
  /** @format float */
  viewSynDiffLastDay?: number | null;
  /** @format int32 */
  viewMidroogRatingRank?: number | null;
  /** @format int32 */
  viewMaalotRatingRank?: number | null;
  ratingGroup?: string | null;
  /** @format int32 */
  ratingGroupRank?: number | null;
  /** @format int32 */
  institutional?: number;
  /** @format float */
  alert1?: number | null;
  /** @format float */
  alert2?: number | null;
  /** @format float */
  alert3?: number | null;
  /** @format float */
  alert4?: number | null;
  /** @format float */
  alert4Calc?: number | null;
  /** @format float */
  alert5?: number | null;
  /** @format float */
  alert6?: number | null;
  /** @format float */
  alert7?: number | null;
  /** @format float */
  alert8?: number | null;
  /** @format float */
  alert8Calc?: number | null;
  /** @format int32 */
  isActive?: number;
}

export interface IDataUserBondDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IDataUserBond[];
}

export interface IDataUserStock {
  stockID?: string | null;
  /** @format int32 */
  symbol?: number;
  name?: string | null;
  issuerID?: string | null;
  equityTypeID?: string | null;
  issuerNum?: string | null;
  issuerName?: string | null;
  equityTypeName?: string | null;
  branchID?: string | null;
  branchName?: string | null;
  tradeStatusID?: string | null;
  tradeStatusName?: string | null;
  viewMidroogRatingMinID?: string | null;
  viewMidroogRatingMinTitle?: string | null;
  /** @format int32 */
  viewMidroogRatingMinRank?: number | null;
  viewMidroogRatingMaxID?: string | null;
  viewMidroogRatingMaxTitle?: string | null;
  /** @format int32 */
  viewMidroogRatingMaxRank?: number | null;
  controllingShareHolder?: string | null;
  /** @format date-time */
  issuingDate?: string | null;
  /** @format date-time */
  lastRateDate?: string | null;
  /** @format float */
  allMonthYield?: number | null;
  /** @format float */
  allYearYield?: number | null;
  /** @format float */
  baseRate?: number | null;
  /** @format float */
  baseRateChangePercentage?: number | null;
  /** @format float */
  beginOfYearYield?: number | null;
  /** @format float */
  currentMarketWorth?: number | null;
  /** @format float */
  dailyTurnover?: number | null;
  /** @format float */
  dividendYield?: number | null;
  /** @format float */
  lastDealRate?: number | null;
  /** @format float */
  listedFortune?: number | null;
  /** @format float */
  pbRatio?: number | null;
  /** @format float */
  peRetio?: number | null;
  /** @format float */
  weekAverageTurnover?: number | null;
  /** @format float */
  weekYield?: number | null;
  /** @format float */
  yesterdayYield?: number | null;
  /** @format float */
  calcPercentchangeAvgYear?: number | null;
  /** @format float */
  calcPercentchangeMinusRf?: number | null;
  /** @format float */
  calcPercentchangeMinusRfAvg?: number | null;
  /** @format float */
  calcPercentchangeMinusRfSD?: number | null;
  /** @format float */
  calcPercentchangeSDYear?: number | null;
  /** @format float */
  calcReturnMinusTAIndexMonth?: number | null;
  /** @format float */
  calcReturnMinusTAIndexWeek?: number | null;
  /** @format float */
  calcReturnMinusTAIndexYear?: number | null;
  /** @format float */
  calcYield30TA125?: number | null;
  /** @format float */
  calcYield365TA125?: number | null;
  /** @format float */
  calcYield7TA125?: number | null;
  /** @format int32 */
  viewNumberOfBonds?: number | null;
  /** @format float */
  alert9?: number | null;
  /** @format float */
  alert10?: number | null;
  /** @format float */
  alert11?: number | null;
  /** @format float */
  alert12?: number | null;
  /** @format float */
  alert12Calc?: number | null;
  /** @format float */
  alert13?: number | null;
  /** @format float */
  alert14?: number | null;
  /** @format float */
  alert15?: number | null;
  /** @format float */
  alert16?: number | null;
  /** @format float */
  alert16Calc?: number | null;
  /** @format float */
  alert17?: number | null;
  /** @format float */
  alert18?: number | null;
  /** @format float */
  alert19?: number | null;
  /** @format int32 */
  isActive?: number;
}

export interface IDataUserStockDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IDataUserStock[];
}

export interface IDateQueryOption {
  select?: string | null;
  filter?: string | null;
  orderBy?: string | null;
  /** @format int32 */
  take?: number;
  /** @format int32 */
  skip?: number;
  count?: boolean;
  symbols?: string | null;
  linkAssetType?: ILinkAssetType;
  /** @format date-time */
  date?: string;
}

export interface IEquityType {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  title?: string | null;
  externalTitle?: string | null;
  bonds?: IBond[] | null;
  bondOnlines?: IBondOnline[] | null;
  stocks?: IStock[] | null;
  stockOnlines?: IStockOnline[] | null;
}

export interface IEquityTypeDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IEquityType[];
}

export interface IGelemStatQueryOption {
  /** @format date-time */
  date?: string;
  linkAssetType?: ILinkAssetType;
  queryType?: IQueryType;
  symbols?: string | null;
  ratingGroup?: boolean;
}

export interface IGelemYieldStatQueryOption {
  /** @format date-time */
  date?: string;
  statRatingType?: IStatRatingType;
  symbols?: string | null;
  ratingGroup?: boolean;
}

export interface IGeneratePasswordRequest {
  email?: string | null;
}

export interface IGlobalParameter {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  version?: string | null;
  /** @format float */
  bankILSRate?: number | null;
  generalBodyScripts?: string | null;
  generalFooterScripts?: string | null;
  generalHeaderScripts?: string | null;
  conversionCodeScripts?: string | null;
}

export interface IGlobalParameterDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IGlobalParameter[];
}

export interface IGrossYieldPanelQueryOption {
  select?: string | null;
  filter?: string | null;
  orderBy?: string | null;
  /** @format int32 */
  take?: number;
  /** @format int32 */
  skip?: number;
  count?: boolean;
  linkAssetType?: ILinkAssetType;
  /** @format float */
  minGrossDuration?: number;
  /** @format float */
  maxGrossDuration?: number;
  /** @format date-time */
  minDate?: string;
  /** @format date-time */
  maxDate?: string;
  symbols?: string | null;
}

export interface IGrossYieldPanelStatQueryOption {
  linkAssetType?: ILinkAssetType;
  /** @format float */
  minGrossDuration?: number;
  /** @format float */
  maxGrossDuration?: number;
  /** @format date-time */
  minDate?: string;
  /** @format date-time */
  maxDate?: string;
  symbols?: string | null;
  statType?: IStatType;
  ratingGroup?: boolean;
  /** @format float */
  minValue?: number;
  /** @format float */
  maxValue?: number;
}

export interface IGtoEndpoint {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  title?: string | null;
  baseUrl?: string | null;
  description?: string | null;
  isIncludeMeta?: boolean;
  defaultIncludeMeta?: string | null;
  isPopulation?: boolean;
  defaultPopulation?: string | null;
  isIncludeSecurities?: boolean;
  defaultIncludeSecurities?: string | null;
  isLevels?: boolean;
  defaultLevels?: string | null;
  isKey?: boolean;
  defaultKey?: string | null;
  isKeys?: boolean;
  defaultKeys?: string | null;
  isSearchValue?: boolean;
  defaultSearchValue?: string | null;
  isFields?: boolean;
  defaultFields?: string | null;
  isMetaFields?: boolean;
  defaultMetaFields?: string | null;
  isSize?: boolean;
  defaultSize?: string | null;
  isIndex?: boolean;
  defaultIndex?: string | null;
  isSecurities?: boolean;
  defaultSecurities?: string | null;
  isTop?: boolean;
  defaultTop?: string | null;
  isIncludeEmptyFields?: boolean;
  defaultIncludeEmptyFields?: string | null;
  isSortby?: boolean;
  defaultSortby?: string | null;
  isDirection?: boolean;
  defaultDirection?: string | null;
  isFromDate?: boolean;
  defaultFromDate?: string | null;
  isToDate?: boolean;
  defaultToDate?: string | null;
  isAllowZeroTurnover?: boolean;
  defaultAllowZeroTurnover?: string | null;
  isSubject?: boolean;
  defaultSubject?: string | null;
  isHeaderOnly?: boolean;
  defaultHeaderOnly?: string | null;
  isCompanies?: boolean;
  defaultCompanies?: string | null;
  isPeriods?: boolean;
  defaultPeriods?: string | null;
  isUsedForHist?: boolean;
}

export interface IGtoEndpointDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IGtoEndpoint[];
}

export interface IGtoRequest {
  baseUrl?: string | null;
  payload?: IStringStringKeyValuePair[] | null;
}

export interface IIssuer {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  title?: string | null;
  externalTitle?: string | null;
  issuerNum?: string | null;
  bonds?: IBond[] | null;
  bondOnlines?: IBondOnline[] | null;
  stocks?: IStock[] | null;
  stockOnlines?: IStockOnline[] | null;
}

export interface IIssuerDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IIssuer[];
}

export interface IJwt {
  token?: string | null;
  refreshToken?: string | null;
}

export interface ILabel {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  title?: string | null;
  languageID?: string | null;
  labelSourceID?: string | null;
  labelKey?: string | null;
  labelSource?: ILabelSource;
  language?: ILanguage;
}

export interface ILabelDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ILabel[];
}

export interface ILabelSource {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  title?: string | null;
  key?: string | null;
  labels?: ILabel[] | null;
}

export interface ILabelSourceDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ILabelSource[];
}

export interface ILanguage {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  title?: string | null;
  direction?: ILanguageDirection;
  culture?: string | null;
  icon?: string | null;
  /** @format int32 */
  rank?: number;
  labels?: ILabel[] | null;
  userProfiles?: IUserProfile[] | null;
}

export enum ILanguageDirection {
  RTL = 1,
  LTR = 2,
}

export interface ILanguageDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ILanguage[];
}

export enum ILinkAssetType {
  ILS = 1,
  CPI = 2,
}

export interface ILinkedAsset {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  title?: string | null;
  externalTitle?: string | null;
  bonds?: IBond[] | null;
  bondOnlines?: IBondOnline[] | null;
}

export interface ILinkedAssetDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ILinkedAsset[];
}

export interface ILogSource {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  title?: string | null;
  appLogs?: IAppLog[] | null;
  dataETLs?: IDataETL[] | null;
}

export interface ILogSourceDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ILogSource[];
}

export interface ILogType {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  title?: string | null;
  appLogs?: IAppLog[] | null;
}

export interface ILogTypeDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ILogType[];
}

export interface ILoginRequest {
  email?: string | null;
  code?: string | null;
}

export interface ILoginResponse {
  jwt?: IJwt;
  /** @format date-time */
  expires?: string;
}

export interface IMaalotRating {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  title?: string | null;
  externalTitle?: string | null;
  /** @format int32 */
  rank?: number;
  bonds?: IBond[] | null;
  bondOnlines?: IBondOnline[] | null;
}

export interface IMaalotRatingArchive {
  /** @format int32 */
  id?: number;
  /**
   * @minLength 0
   * @maxLength 50
   */
  title?: string | null;
  /** @format int32 */
  rank?: number;
}

export interface IMaalotRatingArchiveDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IMaalotRatingArchive[];
}

export interface IMaalotRatingDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IMaalotRating[];
}

export interface IMidroogRating {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  title?: string | null;
  externalTitle?: string | null;
  /** @format int32 */
  rank?: number;
  bonds?: IBond[] | null;
  bondOnlines?: IBondOnline[] | null;
  viewMidroogRatingMinStocks?: IStock[] | null;
  viewMidroogRatingMaxStocks?: IStock[] | null;
  viewMidroogRatingMinStockOnlines?: IStockOnline[] | null;
  viewMidroogRatingMaxStockOnlines?: IStockOnline[] | null;
}

export interface IMidroogRatingArchive {
  /** @format int32 */
  id?: number;
  /**
   * @minLength 0
   * @maxLength 50
   */
  title?: string | null;
  /** @format int32 */
  rank?: number;
}

export interface IMidroogRatingArchiveDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IMidroogRatingArchive[];
}

export interface IMidroogRatingDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IMidroogRating[];
}

export interface IOperation {
  operationType?: IOperationType;
  path?: string | null;
  op?: string | null;
  from?: string | null;
  value?: any;
}

export enum IOperationType {
  Add = 1,
  Remove = 2,
  Replace = 3,
  Move = 4,
  Copy = 5,
  Test = 6,
  Invalid = 7,
}

export interface IQueryOption {
  select?: string | null;
  filter?: string | null;
  orderBy?: string | null;
  /** @format int32 */
  take?: number;
  /** @format int32 */
  skip?: number;
  count?: boolean;
}

export enum IQueryType {
  Delta = 1,
  Yield = 2,
}

export enum IStatRatingType {
  Midroog = 1,
  Maalot = 2,
  Both = 3,
}

export enum IStatType {
  Avg = 1,
  Median = 2,
}

export interface IStock {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  /** @format int32 */
  symbol?: number;
  name?: string | null;
  issuerID?: string | null;
  branchID?: string | null;
  equityTypeID?: string | null;
  tradeStatusID?: string | null;
  viewMidroogRatingMinID?: string | null;
  viewMidroogRatingMaxID?: string | null;
  issuerNum?: string | null;
  issuerName?: string | null;
  controllingShareHolder?: string | null;
  equityTypeName?: string | null;
  branchName?: string | null;
  tradeStatusName?: string | null;
  tradingStage?: string | null;
  countryOfIncorporation?: string | null;
  /** @format float */
  baseRate?: number | null;
  /** @format float */
  lastDealRate?: number | null;
  /** @format float */
  baseRateChangePercentage?: number | null;
  /** @format float */
  allYearYield?: number | null;
  /** @format float */
  weekYield?: number | null;
  /** @format float */
  beginOfYearYield?: number | null;
  /** @format float */
  allMonthYield?: number | null;
  /** @format float */
  peRetio?: number | null;
  /** @format float */
  currentMarketWorth?: number | null;
  /** @format float */
  weekAverageTurnover?: number | null;
  /** @format float */
  dailyTurnover?: number | null;
  /** @format float */
  listedFortune?: number | null;
  /** @format float */
  dividendYield?: number | null;
  /** @format float */
  pbRatio?: number | null;
  /** @format float */
  yesterdayYield?: number | null;
  /** @format date-time */
  lastRateDate?: string | null;
  /** @format date-time */
  issuingDate?: string | null;
  /** @format float */
  calcPercentchangeMinusRf?: number | null;
  /** @format float */
  calcPercentchangeMinusRfAvg?: number | null;
  /** @format float */
  calcPercentchangeMinusRfSD?: number | null;
  /** @format float */
  calcReturnMinusTAIndexWeek?: number | null;
  /** @format float */
  calcReturnMinusTAIndexMonth?: number | null;
  /** @format float */
  calcReturnMinusTAIndexYear?: number | null;
  /** @format float */
  calcPercentchangeAvgYear?: number | null;
  /** @format float */
  calcPercentchangeSDYear?: number | null;
  /** @format int32 */
  viewNumberOfBonds?: number | null;
  /** @format float */
  calcYield7TA125?: number | null;
  /** @format float */
  calcYield30TA125?: number | null;
  /** @format float */
  calcYield365TA125?: number | null;
  /** @format float */
  calcYield90?: number | null;
  /** @format float */
  calcYield90TA125?: number | null;
  issuer?: IIssuer;
  equityType?: IEquityType;
  branch?: IBranch;
  tradeStatus?: ITradeStatus;
  viewMidroogRatingMin?: IMidroogRating;
  viewMidroogRatingMax?: IMidroogRating;
  userProfileStocks?: IUserProfileStock[] | null;
}

export interface IStockArchive {
  /** @format int32 */
  symbol?: number;
  /** @format date-time */
  startdate?: string | null;
  /** @format date-time */
  updatetime?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  issuer?: string | null;
  /** @format float */
  issuer_num?: number | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  controlling_group?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  sector_tase?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  industry?: string | null;
  /** @format float */
  base?: number | null;
  /** @format float */
  last?: number | null;
  /** @format float */
  percentchange?: number | null;
  /** @format float */
  prev_trade_pcg?: number | null;
  /** @format float */
  yield_365?: number | null;
  /** @format float */
  yield_ytd?: number | null;
  /** @format float */
  yield_30?: number | null;
  /** @format float */
  yield_week?: number | null;
  /** @format float */
  pe_ratio_ifrs?: number | null;
  /** @format float */
  sharp_week?: number | null;
  /** @format float */
  sharp_30?: number | null;
  /** @format float */
  sharp_365?: number | null;
  /** @format float */
  sec_market_value?: number | null;
  /** @format float */
  average_deal_sise?: number | null;
  /** @format float */
  average_volume_week?: number | null;
  /** @format float */
  volume?: number | null;
  /** @format float */
  listed_capital?: number | null;
  /** @format float */
  dividend_yield?: number | null;
  /** @format float */
  pb_ratio_ifrs?: number | null;
  /** @format date-time */
  ipo_date?: string | null;
  /** @format date-time */
  last_rate_date?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  trade_status_rezef?: string | null;
  /** @format float */
  calc_percentchange_minus_rf?: number | null;
  /** @format float */
  calc_percentchange_minus_rf_avg?: number | null;
  /** @format float */
  calc_percentchange_minus_rf_sd?: number | null;
  /** @format float */
  calc_return_minus_ta_index_week?: number | null;
  /** @format float */
  calc_return_minus_ta_index_month?: number | null;
  /** @format float */
  calc_return_minus_ta_index_year?: number | null;
  /** @format float */
  calc_percentchange_avg_year?: number | null;
  /** @format float */
  calc_percentchange_sd_year?: number | null;
  /** @format int32 */
  view_number_of_bonds?: number | null;
  /** @format int32 */
  view_midroog_rating_minimum?: number | null;
  /** @format int32 */
  view_midroog_rating_maximum?: number | null;
  /** @format int32 */
  view_issuer_rating_midroog_minimum?: number | null;
  /** @format int32 */
  view_issuer_rating_midroog_maximum?: number | null;
  /** @format float */
  calc_yield_week_ta100?: number | null;
  /** @format float */
  calc_yield_30_ta100?: number | null;
  /** @format float */
  calc_yield_365_ta100?: number | null;
}

export interface IStockArchiveDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IStockArchive[];
}

export interface IStockDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IStock[];
}

export interface IStockGelem {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  /** @format int32 */
  dateDay?: number;
  /** @format int32 */
  dateMonth?: number;
  /** @format int32 */
  dateYear?: number;
  /** @format date-time */
  lastDealDate?: string;
  lastDealTime?: string | null;
  lastDealDateTime?: string | null;
  lastRateTime?: string | null;
  /** @format date-time */
  lastKnownRateDate?: string | null;
  /** @format int32 */
  symbol?: number;
  name?: string | null;
  issuerNum?: string | null;
  issuerName?: string | null;
  controllingShareHolder?: string | null;
  equityTypeName?: string | null;
  branchName?: string | null;
  baseRate?: string | null;
  lastDealRate?: string | null;
  baseRateChangePercentage?: string | null;
  yesterdayYield?: string | null;
  beginOfYearYield?: string | null;
  allYearYield?: string | null;
  weekYield?: string | null;
  allMonthYield?: string | null;
  peRetio?: string | null;
  allMonthSharp?: string | null;
  weekSharp?: string | null;
  allYearSharp?: string | null;
  currentMarketWorth?: string | null;
  weekAverageTurnover?: string | null;
  dividendYield?: string | null;
  pbRatio?: string | null;
  averageDealSize?: string | null;
  dailyTurnover?: string | null;
  listedFortune?: string | null;
  issuingDate?: string | null;
  lastRateDate?: string | null;
  tradingStage?: string | null;
  countryOfIncorporation?: string | null;
}

export interface IStockGelemArchive {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  date_day?: number;
  /** @format int32 */
  date_month?: number;
  /** @format int32 */
  date_year?: number;
  /** @format date-time */
  updatedate?: string;
  /**
   * @minLength 0
   * @maxLength 255
   */
  updatetime?: string | null;
  /** @format int32 */
  symbol?: number;
  /**
   * @minLength 0
   * @maxLength 255
   */
  issuer?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  issuer_num?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  name?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  controlling_group?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  sector_tase?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  industry?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  base?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  last?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  percentchange?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  prev_trade_pcg?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  yield_365?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  yield_ytd?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  yield_30?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  yield_week?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  pe_ratio_ifrs?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  sharp_week?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  sharp_30?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  sharp_365?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  sec_market_value?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  average_deal_sise?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  average_volume_week?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  volume?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  listed_capital?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  dividend_yield?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  pb_ratio_ifrs?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  ipo_date?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  last_rate_date?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  trade_status_rezef?: string | null;
}

export interface IStockGelemArchiveDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IStockGelemArchive[];
}

export interface IStockGelemDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IStockGelem[];
}

export interface IStockMarketExludedDate {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  title?: string | null;
  /** @format date-time */
  dateAndTime?: string;
}

export interface IStockMarketExludedDateDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IStockMarketExludedDate[];
}

export interface IStockOnline {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  /** @format int32 */
  symbol?: number;
  name?: string | null;
  issuerID?: string | null;
  branchID?: string | null;
  equityTypeID?: string | null;
  tradeStatusID?: string | null;
  viewMidroogRatingMinID?: string | null;
  viewMidroogRatingMaxID?: string | null;
  issuerNum?: string | null;
  issuerName?: string | null;
  controllingShareHolder?: string | null;
  equityTypeName?: string | null;
  branchName?: string | null;
  tradeStatusName?: string | null;
  tradingStage?: string | null;
  countryOfIncorporation?: string | null;
  /** @format float */
  baseRate?: number | null;
  /** @format float */
  lastDealRate?: number | null;
  /** @format float */
  baseRateChangePercentage?: number | null;
  /** @format float */
  allYearYield?: number | null;
  /** @format float */
  weekYield?: number | null;
  /** @format float */
  beginOfYearYield?: number | null;
  /** @format float */
  allMonthYield?: number | null;
  /** @format float */
  peRetio?: number | null;
  /** @format float */
  currentMarketWorth?: number | null;
  /** @format float */
  weekAverageTurnover?: number | null;
  /** @format float */
  dailyTurnover?: number | null;
  /** @format float */
  listedFortune?: number | null;
  /** @format float */
  dividendYield?: number | null;
  /** @format float */
  pbRatio?: number | null;
  /** @format float */
  yesterdayYield?: number | null;
  /** @format date-time */
  lastRateDate?: string | null;
  /** @format date-time */
  issuingDate?: string | null;
  /** @format float */
  calcPercentchangeMinusRf?: number | null;
  /** @format float */
  calcPercentchangeMinusRfAvg?: number | null;
  /** @format float */
  calcPercentchangeMinusRfSD?: number | null;
  /** @format float */
  calcReturnMinusTAIndexWeek?: number | null;
  /** @format float */
  calcReturnMinusTAIndexMonth?: number | null;
  /** @format float */
  calcReturnMinusTAIndexYear?: number | null;
  /** @format float */
  calcPercentchangeAvgYear?: number | null;
  /** @format float */
  calcPercentchangeSDYear?: number | null;
  /** @format int32 */
  viewNumberOfBonds?: number | null;
  /** @format float */
  calcYield7TA125?: number | null;
  /** @format float */
  calcYield30TA125?: number | null;
  /** @format float */
  calcYield365TA125?: number | null;
  /** @format float */
  calcYield90?: number | null;
  /** @format float */
  calcYield90TA125?: number | null;
  issuer?: IIssuer;
  equityType?: IEquityType;
  branch?: IBranch;
  tradeStatus?: ITradeStatus;
  viewMidroogRatingMin?: IMidroogRating;
  viewMidroogRatingMax?: IMidroogRating;
}

export interface IStockOnlineDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IStockOnline[];
}

export interface IStringStringKeyValuePair {
  key?: string | null;
  value?: string | null;
}

export interface ISystemBondDefaultAlert {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  /** @format float */
  alert1?: number | null;
  /** @format float */
  alert2?: number | null;
  /** @format float */
  alert3?: number | null;
  /** @format float */
  alert4?: number | null;
  /** @format float */
  alert5?: number | null;
  /** @format float */
  alert6?: number | null;
  /** @format float */
  alert7?: number | null;
  /** @format float */
  alert8?: number | null;
  /** @format float */
  alert9?: number | null;
  /** @format float */
  alert10?: number | null;
  /** @format float */
  alert11?: number | null;
  /** @format float */
  alert12?: number | null;
  /** @format float */
  alert13?: number | null;
  /** @format float */
  alert14?: number | null;
  /** @format float */
  alert15?: number | null;
  isSystemManager?: boolean;
}

export interface ISystemBondDefaultAlertArchive {
  /** @format int32 */
  id?: number;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert1?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert2?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert3?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert4?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert5?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert6?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert7?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert8?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert9?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert10?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert11?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert12?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert13?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert14?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert15?: string | null;
  isSystemManager?: boolean;
}

export interface ISystemBondDefaultAlertArchiveDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ISystemBondDefaultAlertArchive[];
}

export interface ISystemBondDefaultAlertDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ISystemBondDefaultAlert[];
}

export interface ISystemStockDefaultAlert {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  /** @format float */
  alert1?: number | null;
  /** @format float */
  alert2?: number | null;
  /** @format float */
  alert3?: number | null;
  /** @format float */
  alert4?: number | null;
  /** @format float */
  alert5?: number | null;
  /** @format float */
  alert6?: number | null;
  /** @format float */
  alert7?: number | null;
  /** @format float */
  alert8?: number | null;
  /** @format float */
  alert9?: number | null;
  /** @format float */
  alert10?: number | null;
  /** @format float */
  alert11?: number | null;
  /** @format float */
  alert12?: number | null;
  /** @format float */
  alert13?: number | null;
  /** @format float */
  alert14?: number | null;
  /** @format float */
  alert15?: number | null;
  isSystemManager?: boolean;
}

export interface ISystemStockDefaultAlertArchive {
  /** @format int32 */
  id?: number;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert1?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert2?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert3?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert4?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert5?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert6?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert7?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert8?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert9?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert10?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert11?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert12?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert13?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert14?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert15?: string | null;
  isSystemManager?: boolean;
}

export interface ISystemStockDefaultAlertArchiveDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ISystemStockDefaultAlertArchive[];
}

export interface ISystemStockDefaultAlertDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ISystemStockDefaultAlert[];
}

export interface ITradeStatus {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  title?: string | null;
  externalTitle?: string | null;
  stocks?: IStock[] | null;
  stockOnlines?: IStockOnline[] | null;
}

export interface ITradeStatusDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: ITradeStatus[];
}

export interface IUserAlertsTotal {
  /** @format int32 */
  alert1Total?: number;
  /** @format int32 */
  alert2Total?: number;
  /** @format int32 */
  alert3Total?: number;
  /** @format int32 */
  alert4Total?: number;
  /** @format int32 */
  alert5Total?: number;
  /** @format int32 */
  alert6Total?: number;
  /** @format int32 */
  alert7Total?: number;
  /** @format int32 */
  alert8Total?: number;
  /** @format int32 */
  alert9Total?: number;
  /** @format int32 */
  alert10Total?: number;
  /** @format int32 */
  alert11Total?: number;
  /** @format int32 */
  alert12Total?: number;
  /** @format int32 */
  alert13Total?: number;
  /** @format int32 */
  alert14Total?: number;
  /** @format int32 */
  alert15Total?: number;
  /** @format int32 */
  alert16Total?: number;
  /** @format int32 */
  alert17Total?: number;
  /** @format int32 */
  alert18Total?: number;
  /** @format int32 */
  alert19Total?: number;
}

export interface IUserProfile {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  appIdentityUserID?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  fullName?: string | null;
  userPhoto?: string | null;
  /** @format date-time */
  startDate?: string | null;
  userProfilePermissionID?: string | null;
  languageID?: string | null;
  /** @format date-time */
  generatePasswordEndDate?: string | null;
  mobilePhone?: string | null;
  /** @format date-time */
  lastActivityDate?: string | null;
  isSystemAlerts?: boolean;
  /** @format int32 */
  userID?: number | null;
  userProfilePermission?: IUserProfilePermission;
  language?: ILanguage;
  userProfileBonds?: IUserProfileBond[] | null;
  userProfileStocks?: IUserProfileStock[] | null;
  userProfileBondDefaultAlerts?: IUserProfileBondDefaultAlert[] | null;
  userProfileStockDefaultAlerts?: IUserProfileStockDefaultAlert[] | null;
  userProfileHistAlerts?: IUserProfileHistAlert[] | null;
  dataETLs?: IDataETL[] | null;
}

export interface IUserProfileArchive {
  /** @format int32 */
  id?: number;
  /** @format date-time */
  dateAndTime?: string;
  /**
   * @minLength 0
   * @maxLength 250
   */
  name?: string | null;
  /**
   * @minLength 0
   * @maxLength 120
   */
  email?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  password?: string | null;
  /** @format int32 */
  permissionID?: number;
  isActive?: boolean;
  isSystemAlerts?: boolean;
}

export interface IUserProfileArchiveDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IUserProfileArchive[];
}

export interface IUserProfileBond {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  userProfileID?: string | null;
  bondID?: string | null;
  /** @format int32 */
  symbol?: number;
  /** @format float */
  alert1?: number | null;
  /** @format float */
  alert2?: number | null;
  /** @format float */
  alert3?: number | null;
  /** @format float */
  alert4?: number | null;
  /** @format float */
  alert5?: number | null;
  /** @format float */
  alert6?: number | null;
  /** @format float */
  alert7?: number | null;
  /** @format float */
  alert8?: number | null;
  /** @format float */
  alert9?: number | null;
  /** @format float */
  alert10?: number | null;
  /** @format float */
  alert11?: number | null;
  /** @format float */
  alert12?: number | null;
  /** @format float */
  alert13?: number | null;
  /** @format float */
  alert14?: number | null;
  /** @format float */
  alert15?: number | null;
  userProfile?: IUserProfile;
  bond?: IBond;
}

export interface IUserProfileBondArchive {
  /** @format int32 */
  userID?: number;
  /** @format int32 */
  symbol?: number;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert1?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert2?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert3?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert4?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert5?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert6?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert7?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert8?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert9?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert10?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert11?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert12?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert13?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert14?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert15?: string | null;
}

export interface IUserProfileBondArchiveDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IUserProfileBondArchive[];
}

export interface IUserProfileBondDefaultAlert {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  userProfileID?: string | null;
  /** @format float */
  alert1?: number | null;
  /** @format float */
  alert2?: number | null;
  /** @format float */
  alert3?: number | null;
  /** @format float */
  alert4?: number | null;
  /** @format float */
  alert5?: number | null;
  /** @format float */
  alert6?: number | null;
  /** @format float */
  alert7?: number | null;
  /** @format float */
  alert8?: number | null;
  /** @format float */
  alert9?: number | null;
  /** @format float */
  alert10?: number | null;
  /** @format float */
  alert11?: number | null;
  /** @format float */
  alert12?: number | null;
  /** @format float */
  alert13?: number | null;
  /** @format float */
  alert14?: number | null;
  /** @format float */
  alert15?: number | null;
  userProfile?: IUserProfile;
}

export interface IUserProfileBondDefaultAlertArchive {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  userID?: number;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert1?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert2?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert3?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert4?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert5?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert6?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert7?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert8?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert9?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert10?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert11?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert12?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert13?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert14?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert15?: string | null;
}

export interface IUserProfileBondDefaultAlertArchiveDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IUserProfileBondDefaultAlertArchive[];
}

export interface IUserProfileBondDefaultAlertDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IUserProfileBondDefaultAlert[];
}

export interface IUserProfileBondDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IUserProfileBond[];
}

export interface IUserProfileDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IUserProfile[];
}

export interface IUserProfileHistAlert {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  userProfileID?: string | null;
  /** @format date-time */
  entryDate?: string;
  title?: string | null;
  fileURL?: string | null;
  userProfile?: IUserProfile;
}

export interface IUserProfileHistAlertDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IUserProfileHistAlert[];
}

export interface IUserProfilePermission {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  title?: string | null;
  isAllowToEditSetting?: boolean;
  isAllowToEditLabels?: boolean;
  isAllowToEditProfile?: boolean;
  isAllowToEditPermissions?: boolean;
  isAllowToViewLog?: boolean;
  isAllowToViewSensitiveInformation?: boolean;
  isAllowToResetSensitiveInformation?: boolean;
  color?: string | null;
  userProfiles?: IUserProfile[] | null;
}

export interface IUserProfilePermissionDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IUserProfilePermission[];
}

export interface IUserProfileStock {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  userProfileID?: string | null;
  stockID?: string | null;
  /** @format int32 */
  symbol?: number;
  /** @format float */
  alert1?: number | null;
  /** @format float */
  alert2?: number | null;
  /** @format float */
  alert3?: number | null;
  /** @format float */
  alert4?: number | null;
  /** @format float */
  alert5?: number | null;
  /** @format float */
  alert6?: number | null;
  /** @format float */
  alert7?: number | null;
  /** @format float */
  alert8?: number | null;
  /** @format float */
  alert9?: number | null;
  /** @format float */
  alert10?: number | null;
  /** @format float */
  alert11?: number | null;
  /** @format float */
  alert12?: number | null;
  /** @format float */
  alert13?: number | null;
  /** @format float */
  alert14?: number | null;
  /** @format float */
  alert15?: number | null;
  userProfile?: IUserProfile;
  stock?: IStock;
}

export interface IUserProfileStockArchive {
  /** @format int32 */
  userID?: number;
  /** @format int32 */
  symbol?: number;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert1?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert2?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert3?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert4?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert5?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert6?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert7?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert8?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert9?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert10?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert11?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert12?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert13?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert14?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert15?: string | null;
}

export interface IUserProfileStockArchiveDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IUserProfileStockArchive[];
}

export interface IUserProfileStockDefaultAlert {
  id?: string | null;
  /** @format int32 */
  rowIndex?: number;
  isActive?: boolean;
  /** @format int32 */
  oldAppID?: number | null;
  userProfileID?: string | null;
  /** @format float */
  alert1?: number | null;
  /** @format float */
  alert2?: number | null;
  /** @format float */
  alert3?: number | null;
  /** @format float */
  alert4?: number | null;
  /** @format float */
  alert5?: number | null;
  /** @format float */
  alert6?: number | null;
  /** @format float */
  alert7?: number | null;
  /** @format float */
  alert8?: number | null;
  /** @format float */
  alert9?: number | null;
  /** @format float */
  alert10?: number | null;
  /** @format float */
  alert11?: number | null;
  /** @format float */
  alert12?: number | null;
  /** @format float */
  alert13?: number | null;
  /** @format float */
  alert14?: number | null;
  /** @format float */
  alert15?: number | null;
  userProfile?: IUserProfile;
}

export interface IUserProfileStockDefaultAlertArchive {
  /** @format int32 */
  id?: number;
  /** @format int32 */
  userID?: number;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert1?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert2?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert3?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert4?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert5?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert6?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert7?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert8?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert9?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert10?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert11?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert12?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert13?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert14?: string | null;
  /**
   * @minLength 0
   * @maxLength 50
   */
  alert15?: string | null;
}

export interface IUserProfileStockDefaultAlertArchiveDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IUserProfileStockDefaultAlertArchive[];
}

export interface IUserProfileStockDefaultAlertDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IUserProfileStockDefaultAlert[];
}

export interface IUserProfileStockDynamicResponse {
  /** @format int32 */
  count?: number | null;
  items: IUserProfileStock[];
}

export interface IUserQueryOption {
  select?: string | null;
  filter?: string | null;
  orderBy?: string | null;
  /** @format int32 */
  take?: number;
  /** @format int32 */
  skip?: number;
  count?: boolean;
  userProfileID?: string | null;
}

export interface IYieldQueryOption {
  select?: string | null;
  filter?: string | null;
  orderBy?: string | null;
  /** @format int32 */
  take?: number;
  /** @format int32 */
  skip?: number;
  count?: boolean;
  symbols?: string | null;
  countriesOfIncorporation?: string | null;
  /** @format float */
  minGrossYieldToMaturity?: number | null;
  /** @format float */
  maxGrossYieldToMaturity?: number | null;
  /** @format date-time */
  date?: string;
}

export interface IAlertsHelperGetUserAlertsListParams {
  userProfileID?: string;
}

export interface IAppLogArchivesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IAppLogsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IBondArchivesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IBondGelemArchivesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IBondGelemsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IBondOnlinesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IBondsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IBondsHelperGetUserBondsDynamicListParams {
  UserProfileID?: string;
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IBondsHelperGetUserBondsOnlineDynamicListParams {
  UserProfileID?: string;
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IBondsHelperGetUserBondStatsListParams {
  UserProfileID?: string;
  LinkedAssetID?: string;
  Type?: string;
  RatingGroup?: boolean;
}

export interface IBondsHelperGetSystemBondsDynamicListParams {
  UserProfileID?: string;
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IBondsHelperDeleteDuplicationItemsDeleteParams {
  /** @format int32 */
  date?: number;
}

export interface IBranchesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IDataEtLsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IEquityTypesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IGlobalParametersGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IGtoEndpointsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IIssuersGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ILabelsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ILabelSourcesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ILanguagesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ILinkedAssetsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ILogSourcesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ILogTypesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IMaalotRatingArchivesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IMaalotRatingsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IMediaUploadsRemoveFileFromCloudUpdateParams {
  filePath?: string;
  isImage?: boolean;
}

export interface IMediaUploadsDownloadFileFromCloudListParams {
  fileName?: string;
}

export interface IMidroogRatingArchivesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IMidroogRatingsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IStockArchivesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IStockGelemArchivesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IStockGelemsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IStockMarketExludedDatesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IStockOnlinesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IStocksGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IStocksHelperGetUserStocksDynamicListParams {
  UserProfileID?: string;
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IStocksHelperGetSystemStocksDynamicListParams {
  UserProfileID?: string;
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ISystemBondDefaultAlertArchivesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ISystemBondDefaultAlertsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ISystemStockDefaultAlertArchivesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ISystemStockDefaultAlertsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface ITradeStatusesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IUserProfileArchivesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IUserProfileBondArchivesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IUserProfileBondDefaultAlertArchivesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IUserProfileBondDefaultAlertsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IUserProfileBondsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IUserProfileHistAlertsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IUserProfilePermissionsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IUserProfilesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IUserProfileStockArchivesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IUserProfileStockDefaultAlertArchivesGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IUserProfileStockDefaultAlertsGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

export interface IUserProfileStocksGetAllDynamicListParams {
  Select?: string;
  Filter?: string;
  OrderBy?: string;
  /** @format int32 */
  Take?: number;
  /** @format int32 */
  Skip?: number;
  Count?: boolean;
}

import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType,
} from 'axios';
import axios from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig,
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title RedLight API
 * @version December 2024
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Accounts
     * @name AccountsGeneratePasswordCreate
     * @request POST:/api/Accounts/GeneratePassword
     * @secure
     */
    accountsGeneratePasswordCreate: (data: IGeneratePasswordRequest, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Accounts/GeneratePassword`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Accounts
     * @name AccountsLoginWithCodeCreate
     * @request POST:/api/Accounts/LoginWithCode
     * @secure
     */
    accountsLoginWithCodeCreate: (data: ILoginRequest, params: RequestParams = {}) =>
      this.request<ILoginResponse, any>({
        path: `/api/Accounts/LoginWithCode`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Accounts
     * @name AccountsLogoutCreate
     * @request POST:/api/Accounts/Logout
     * @secure
     */
    accountsLogoutCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Accounts/Logout`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Accounts
     * @name AccountsRefreshTokenCreate
     * @request POST:/api/Accounts/RefreshToken
     * @secure
     */
    accountsRefreshTokenCreate: (data: IJwt, params: RequestParams = {}) =>
      this.request<IJwt, any>({
        path: `/api/Accounts/RefreshToken`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Accounts
     * @name AccountsGetCurrentAppUserList
     * @request GET:/api/Accounts/GetCurrentAppUser
     * @secure
     */
    accountsGetCurrentAppUserList: (params: RequestParams = {}) =>
      this.request<ICurrentUserProfileResponse, any>({
        path: `/api/Accounts/GetCurrentAppUser`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AlertsHelper
     * @name AlertsHelperGetUserAlertsList
     * @request GET:/api/AlertsHelper/GetUserAlerts
     * @secure
     */
    alertsHelperGetUserAlertsList: (
      query: IAlertsHelperGetUserAlertsListParams,
      params: RequestParams = {},
    ) =>
      this.request<IUserAlertsTotal, any>({
        path: `/api/AlertsHelper/GetUserAlerts`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AppLogArchives
     * @name AppLogArchivesGetAllDynamicList
     * @request GET:/api/AppLogArchives/GetAllDynamic
     * @secure
     */
    appLogArchivesGetAllDynamicList: (
      query: IAppLogArchivesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IAppLogArchiveDynamicResponse, any>({
        path: `/api/AppLogArchives/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AppLogArchives
     * @name AppLogArchivesGetAllItemsDynamicCreate
     * @request POST:/api/AppLogArchives/GetAllItemsDynamic
     * @secure
     */
    appLogArchivesGetAllItemsDynamicCreate: (data: IQueryOption, params: RequestParams = {}) =>
      this.request<IAppLogArchiveDynamicResponse, any>({
        path: `/api/AppLogArchives/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AppLogs
     * @name AppLogsDeleteDelete
     * @request DELETE:/api/AppLogs/Delete/{id}
     * @secure
     */
    appLogsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IAppLog, any>({
        path: `/api/AppLogs/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AppLogs
     * @name AppLogsGetAllDynamicList
     * @request GET:/api/AppLogs/GetAllDynamic
     * @secure
     */
    appLogsGetAllDynamicList: (
      query: IAppLogsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IAppLogDynamicResponse, any>({
        path: `/api/AppLogs/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AppLogs
     * @name AppLogsGetAllItemsDynamicCreate
     * @request POST:/api/AppLogs/GetAllItemsDynamic
     * @secure
     */
    appLogsGetAllItemsDynamicCreate: (data: IQueryOption, params: RequestParams = {}) =>
      this.request<IAppLogDynamicResponse, any>({
        path: `/api/AppLogs/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AppLogs
     * @name AppLogsCreateCreate
     * @request POST:/api/AppLogs/Create
     * @secure
     */
    appLogsCreateCreate: (data: IAppLog, params: RequestParams = {}) =>
      this.request<IAppLog, any>({
        path: `/api/AppLogs/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags AppLogs
     * @name AppLogsPatchPartialUpdate
     * @request PATCH:/api/AppLogs/Patch/{id}
     * @secure
     */
    appLogsPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<IAppLog, any>({
        path: `/api/AppLogs/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondArchives
     * @name BondArchivesGetAllDynamicList
     * @request GET:/api/BondArchives/GetAllDynamic
     * @secure
     */
    bondArchivesGetAllDynamicList: (
      query: IBondArchivesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IBondArchiveDynamicResponse, any>({
        path: `/api/BondArchives/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondArchives
     * @name BondArchivesGetAllItemsDynamicCreate
     * @request POST:/api/BondArchives/GetAllItemsDynamic
     * @secure
     */
    bondArchivesGetAllItemsDynamicCreate: (data: IQueryOption, params: RequestParams = {}) =>
      this.request<IBondArchiveDynamicResponse, any>({
        path: `/api/BondArchives/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondGelemArchives
     * @name BondGelemArchivesGetAllDynamicList
     * @request GET:/api/BondGelemArchives/GetAllDynamic
     * @secure
     */
    bondGelemArchivesGetAllDynamicList: (
      query: IBondGelemArchivesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IBondGelemArchiveDynamicResponse, any>({
        path: `/api/BondGelemArchives/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondGelemArchives
     * @name BondGelemArchivesGetAllItemsDynamicCreate
     * @request POST:/api/BondGelemArchives/GetAllItemsDynamic
     * @secure
     */
    bondGelemArchivesGetAllItemsDynamicCreate: (data: IQueryOption, params: RequestParams = {}) =>
      this.request<IBondGelemArchiveDynamicResponse, any>({
        path: `/api/BondGelemArchives/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondGelems
     * @name BondGelemsDeleteDelete
     * @request DELETE:/api/BondGelems/Delete/{id}
     * @secure
     */
    bondGelemsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IBondGelem, any>({
        path: `/api/BondGelems/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondGelems
     * @name BondGelemsGetAllDynamicList
     * @request GET:/api/BondGelems/GetAllDynamic
     * @secure
     */
    bondGelemsGetAllDynamicList: (
      query: IBondGelemsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IBondGelemDynamicResponse, any>({
        path: `/api/BondGelems/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondGelems
     * @name BondGelemsGetAllItemsDynamicCreate
     * @request POST:/api/BondGelems/GetAllItemsDynamic
     * @secure
     */
    bondGelemsGetAllItemsDynamicCreate: (data: IQueryOption, params: RequestParams = {}) =>
      this.request<IBondGelemDynamicResponse, any>({
        path: `/api/BondGelems/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondGelems
     * @name BondGelemsCreateCreate
     * @request POST:/api/BondGelems/Create
     * @secure
     */
    bondGelemsCreateCreate: (data: IBondGelem, params: RequestParams = {}) =>
      this.request<IBondGelem, any>({
        path: `/api/BondGelems/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondGelems
     * @name BondGelemsPatchPartialUpdate
     * @request PATCH:/api/BondGelems/Patch/{id}
     * @secure
     */
    bondGelemsPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<IBondGelem, any>({
        path: `/api/BondGelems/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondOnlines
     * @name BondOnlinesDeleteDelete
     * @request DELETE:/api/BondOnlines/Delete/{id}
     * @secure
     */
    bondOnlinesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IBondOnline, any>({
        path: `/api/BondOnlines/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondOnlines
     * @name BondOnlinesGetAllDynamicList
     * @request GET:/api/BondOnlines/GetAllDynamic
     * @secure
     */
    bondOnlinesGetAllDynamicList: (
      query: IBondOnlinesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IBondOnlineDynamicResponse, any>({
        path: `/api/BondOnlines/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondOnlines
     * @name BondOnlinesGetAllItemsDynamicCreate
     * @request POST:/api/BondOnlines/GetAllItemsDynamic
     * @secure
     */
    bondOnlinesGetAllItemsDynamicCreate: (data: IQueryOption, params: RequestParams = {}) =>
      this.request<IBondOnlineDynamicResponse, any>({
        path: `/api/BondOnlines/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondOnlines
     * @name BondOnlinesCreateCreate
     * @request POST:/api/BondOnlines/Create
     * @secure
     */
    bondOnlinesCreateCreate: (data: IBondOnline, params: RequestParams = {}) =>
      this.request<IBondOnline, any>({
        path: `/api/BondOnlines/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondOnlines
     * @name BondOnlinesPatchPartialUpdate
     * @request PATCH:/api/BondOnlines/Patch/{id}
     * @secure
     */
    bondOnlinesPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<IBondOnline, any>({
        path: `/api/BondOnlines/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bonds
     * @name BondsDeleteDelete
     * @request DELETE:/api/Bonds/Delete/{id}
     * @secure
     */
    bondsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IBond, any>({
        path: `/api/Bonds/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bonds
     * @name BondsGetAllDynamicList
     * @request GET:/api/Bonds/GetAllDynamic
     * @secure
     */
    bondsGetAllDynamicList: (query: IBondsGetAllDynamicListParams, params: RequestParams = {}) =>
      this.request<IBondDynamicResponse, any>({
        path: `/api/Bonds/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bonds
     * @name BondsGetAllItemsDynamicCreate
     * @request POST:/api/Bonds/GetAllItemsDynamic
     * @secure
     */
    bondsGetAllItemsDynamicCreate: (data: IQueryOption, params: RequestParams = {}) =>
      this.request<IBondDynamicResponse, any>({
        path: `/api/Bonds/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bonds
     * @name BondsCreateCreate
     * @request POST:/api/Bonds/Create
     * @secure
     */
    bondsCreateCreate: (data: IBond, params: RequestParams = {}) =>
      this.request<IBond, any>({
        path: `/api/Bonds/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Bonds
     * @name BondsPatchPartialUpdate
     * @request PATCH:/api/Bonds/Patch/{id}
     * @secure
     */
    bondsPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<IBond, any>({
        path: `/api/Bonds/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondsHelper
     * @name BondsHelperGetGelemBondYieldItemsDynamicCreate
     * @request POST:/api/BondsHelper/GetGelemBondYieldItemsDynamic
     * @secure
     */
    bondsHelperGetGelemBondYieldItemsDynamicCreate: (
      data: IYieldQueryOption,
      params: RequestParams = {},
    ) =>
      this.request<IDataGelemBondDynamicResponse, any>({
        path: `/api/BondsHelper/GetGelemBondYieldItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondsHelper
     * @name BondsHelperGetGelemBondYieldStatsCreate
     * @request POST:/api/BondsHelper/GetGelemBondYieldStats
     * @secure
     */
    bondsHelperGetGelemBondYieldStatsCreate: (
      data: IGelemYieldStatQueryOption,
      params: RequestParams = {},
    ) =>
      this.request<IDataBondStatItem[], any>({
        path: `/api/BondsHelper/GetGelemBondYieldStats`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondsHelper
     * @name BondsHelperGetGrossYieldToMaturityStatCreate
     * @request POST:/api/BondsHelper/GetGrossYieldToMaturityStat
     * @secure
     */
    bondsHelperGetGrossYieldToMaturityStatCreate: (
      data: IGrossYieldPanelStatQueryOption,
      params: RequestParams = {},
    ) =>
      this.request<IDataGrossYieldToMaturityStat[], any>({
        path: `/api/BondsHelper/GetGrossYieldToMaturityStat`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondsHelper
     * @name BondsHelperGetGrossYieldToMaturityMarketStatCreate
     * @request POST:/api/BondsHelper/GetGrossYieldToMaturityMarketStat
     * @secure
     */
    bondsHelperGetGrossYieldToMaturityMarketStatCreate: (
      data: IGrossYieldPanelStatQueryOption,
      params: RequestParams = {},
    ) =>
      this.request<IDataGrossYieldToMaturityMarketStat[], any>({
        path: `/api/BondsHelper/GetGrossYieldToMaturityMarketStat`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondsHelper
     * @name BondsHelperGetGrossYieldToMaturityDeltaByDurationStatCreate
     * @request POST:/api/BondsHelper/GetGrossYieldToMaturityDeltaByDurationStat
     * @secure
     */
    bondsHelperGetGrossYieldToMaturityDeltaByDurationStatCreate: (
      data: IGrossYieldPanelStatQueryOption,
      params: RequestParams = {},
    ) =>
      this.request<IDataGrossYieldToMaturityDeltaByDurationStat[], any>({
        path: `/api/BondsHelper/GetGrossYieldToMaturityDeltaByDurationStat`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondsHelper
     * @name BondsHelperGetGrossYieldToMaturityDeltaByDurationMarketStatCreate
     * @request POST:/api/BondsHelper/GetGrossYieldToMaturityDeltaByDurationMarketStat
     * @secure
     */
    bondsHelperGetGrossYieldToMaturityDeltaByDurationMarketStatCreate: (
      data: IGrossYieldPanelStatQueryOption,
      params: RequestParams = {},
    ) =>
      this.request<IDataGrossYieldToMaturityDeltaByDurationMarketStat[], any>({
        path: `/api/BondsHelper/GetGrossYieldToMaturityDeltaByDurationMarketStat`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondsHelper
     * @name BondsHelperGetGrossYieldPanelItemsDynamicCreate
     * @request POST:/api/BondsHelper/GetGrossYieldPanelItemsDynamic
     * @secure
     */
    bondsHelperGetGrossYieldPanelItemsDynamicCreate: (
      data: IGrossYieldPanelQueryOption,
      params: RequestParams = {},
    ) =>
      this.request<IDataGrossYieldPanelItemDynamicResponse, any>({
        path: `/api/BondsHelper/GetGrossYieldPanelItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondsHelper
     * @name BondsHelperGetGrossYieldMarketPanelItemsDynamicCreate
     * @request POST:/api/BondsHelper/GetGrossYieldMarketPanelItemsDynamic
     * @secure
     */
    bondsHelperGetGrossYieldMarketPanelItemsDynamicCreate: (
      data: IGrossYieldPanelQueryOption,
      params: RequestParams = {},
    ) =>
      this.request<IDataGrossYieldMarketPanelItemDynamicResponse, any>({
        path: `/api/BondsHelper/GetGrossYieldMarketPanelItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondsHelper
     * @name BondsHelperGetGelemBondItemsDynamicCreate
     * @request POST:/api/BondsHelper/GetGelemBondItemsDynamic
     * @secure
     */
    bondsHelperGetGelemBondItemsDynamicCreate: (
      data: IDateQueryOption,
      params: RequestParams = {},
    ) =>
      this.request<IDataGelemBondDynamicResponse, any>({
        path: `/api/BondsHelper/GetGelemBondItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondsHelper
     * @name BondsHelperGetGelemBondMarketItemsDynamicCreate
     * @request POST:/api/BondsHelper/GetGelemBondMarketItemsDynamic
     * @secure
     */
    bondsHelperGetGelemBondMarketItemsDynamicCreate: (
      data: IDateQueryOption,
      params: RequestParams = {},
    ) =>
      this.request<IDataGelemMarketBondDynamicResponse, any>({
        path: `/api/BondsHelper/GetGelemBondMarketItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondsHelper
     * @name BondsHelperGetGelemBondStatsCreate
     * @request POST:/api/BondsHelper/GetGelemBondStats
     * @secure
     */
    bondsHelperGetGelemBondStatsCreate: (data: IGelemStatQueryOption, params: RequestParams = {}) =>
      this.request<IDataBondStatItem[], any>({
        path: `/api/BondsHelper/GetGelemBondStats`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondsHelper
     * @name BondsHelperGetGelemBondMarketStatsCreate
     * @request POST:/api/BondsHelper/GetGelemBondMarketStats
     * @secure
     */
    bondsHelperGetGelemBondMarketStatsCreate: (
      data: IGelemStatQueryOption,
      params: RequestParams = {},
    ) =>
      this.request<IDataBondStatItem[], any>({
        path: `/api/BondsHelper/GetGelemBondMarketStats`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondsHelper
     * @name BondsHelperGetUserBondsDynamicList
     * @request GET:/api/BondsHelper/GetUserBondsDynamic
     * @secure
     */
    bondsHelperGetUserBondsDynamicList: (
      query: IBondsHelperGetUserBondsDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IDataUserBondDynamicResponse, any>({
        path: `/api/BondsHelper/GetUserBondsDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondsHelper
     * @name BondsHelperGetUserBondItemsDynamicCreate
     * @request POST:/api/BondsHelper/GetUserBondItemsDynamic
     * @secure
     */
    bondsHelperGetUserBondItemsDynamicCreate: (
      data: IUserQueryOption,
      params: RequestParams = {},
    ) =>
      this.request<IDataUserBondDynamicResponse, any>({
        path: `/api/BondsHelper/GetUserBondItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondsHelper
     * @name BondsHelperGetUserBondsOnlineDynamicList
     * @request GET:/api/BondsHelper/GetUserBondsOnlineDynamic
     * @secure
     */
    bondsHelperGetUserBondsOnlineDynamicList: (
      query: IBondsHelperGetUserBondsOnlineDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IDataUserBondDynamicResponse, any>({
        path: `/api/BondsHelper/GetUserBondsOnlineDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondsHelper
     * @name BondsHelperGetUserBondItemsOnlineDynamicCreate
     * @request POST:/api/BondsHelper/GetUserBondItemsOnlineDynamic
     * @secure
     */
    bondsHelperGetUserBondItemsOnlineDynamicCreate: (
      data: IUserQueryOption,
      params: RequestParams = {},
    ) =>
      this.request<IDataUserBondDynamicResponse, any>({
        path: `/api/BondsHelper/GetUserBondItemsOnlineDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondsHelper
     * @name BondsHelperGetUserBondStatsList
     * @request GET:/api/BondsHelper/GetUserBondStats
     * @secure
     */
    bondsHelperGetUserBondStatsList: (
      query: IBondsHelperGetUserBondStatsListParams,
      params: RequestParams = {},
    ) =>
      this.request<IDataBondStatItem[], any>({
        path: `/api/BondsHelper/GetUserBondStats`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondsHelper
     * @name BondsHelperGetSystemBondsDynamicList
     * @request GET:/api/BondsHelper/GetSystemBondsDynamic
     * @secure
     */
    bondsHelperGetSystemBondsDynamicList: (
      query: IBondsHelperGetSystemBondsDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IDataBondDynamicResponse, any>({
        path: `/api/BondsHelper/GetSystemBondsDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondsHelper
     * @name BondsHelperGetSystemBondItemsDynamicCreate
     * @request POST:/api/BondsHelper/GetSystemBondItemsDynamic
     * @secure
     */
    bondsHelperGetSystemBondItemsDynamicCreate: (
      data: IUserQueryOption,
      params: RequestParams = {},
    ) =>
      this.request<IDataBondDynamicResponse, any>({
        path: `/api/BondsHelper/GetSystemBondItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondsHelper
     * @name BondsHelperDeleteDuplicationItemsDelete
     * @request DELETE:/api/BondsHelper/DeleteDuplicationItems
     * @secure
     */
    bondsHelperDeleteDuplicationItemsDelete: (
      query: IBondsHelperDeleteDuplicationItemsDeleteParams,
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/BondsHelper/DeleteDuplicationItems`,
        method: 'DELETE',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondsHelper
     * @name BondsHelperFillingMissingRawDateCreate
     * @request POST:/api/BondsHelper/FillingMissingRawDate
     * @secure
     */
    bondsHelperFillingMissingRawDateCreate: (data: string, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/BondsHelper/FillingMissingRawDate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondsHelper
     * @name BondsHelperInsertNewUserBondsAlertsCreate
     * @request POST:/api/BondsHelper/InsertNewUserBondsAlerts
     * @secure
     */
    bondsHelperInsertNewUserBondsAlertsCreate: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/BondsHelper/InsertNewUserBondsAlerts`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondsHelper
     * @name BondsHelperDeleteUserBondsAlertsDelete
     * @request DELETE:/api/BondsHelper/DeleteUserBondsAlerts
     * @secure
     */
    bondsHelperDeleteUserBondsAlertsDelete: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/BondsHelper/DeleteUserBondsAlerts`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondsHelper
     * @name BondsHelperResetUserBondsAlertsToUserDefaultUpdate
     * @request PUT:/api/BondsHelper/ResetUserBondsAlertsToUserDefault
     * @secure
     */
    bondsHelperResetUserBondsAlertsToUserDefaultUpdate: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/BondsHelper/ResetUserBondsAlertsToUserDefault`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondsHelper
     * @name BondsHelperResetUserBondsAlertsToSystemDefaultUpdate
     * @request PUT:/api/BondsHelper/ResetUserBondsAlertsToSystemDefault
     * @secure
     */
    bondsHelperResetUserBondsAlertsToSystemDefaultUpdate: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/BondsHelper/ResetUserBondsAlertsToSystemDefault`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondsHelper
     * @name BondsHelperLoadUserBondsAlertsBySymbolsCreate
     * @request POST:/api/BondsHelper/LoadUserBondsAlertsBySymbols
     * @secure
     */
    bondsHelperLoadUserBondsAlertsBySymbolsCreate: (data: string[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/BondsHelper/LoadUserBondsAlertsBySymbols`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondsHelper
     * @name BondsHelperLoadUserBondsAlertsByIssuersCreate
     * @request POST:/api/BondsHelper/LoadUserBondsAlertsByIssuers
     * @secure
     */
    bondsHelperLoadUserBondsAlertsByIssuersCreate: (data: string[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/BondsHelper/LoadUserBondsAlertsByIssuers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondsHelper
     * @name BondsHelperLoadUserBondsAlertsByIDsCreate
     * @request POST:/api/BondsHelper/LoadUserBondsAlertsByIDs
     * @secure
     */
    bondsHelperLoadUserBondsAlertsByIDsCreate: (data: string[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/BondsHelper/LoadUserBondsAlertsByIDs`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags BondsHelper
     * @name BondsHelperUnLoadUserBondsAlertsByIDsCreate
     * @request POST:/api/BondsHelper/UnLoadUserBondsAlertsByIDs
     * @secure
     */
    bondsHelperUnLoadUserBondsAlertsByIDsCreate: (data: string[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/BondsHelper/UnLoadUserBondsAlertsByIDs`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Branches
     * @name BranchesDeleteDelete
     * @request DELETE:/api/Branches/Delete/{id}
     * @secure
     */
    branchesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IBranch, any>({
        path: `/api/Branches/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Branches
     * @name BranchesGetAllDynamicList
     * @request GET:/api/Branches/GetAllDynamic
     * @secure
     */
    branchesGetAllDynamicList: (
      query: IBranchesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IBranchDynamicResponse, any>({
        path: `/api/Branches/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Branches
     * @name BranchesGetAllItemsDynamicCreate
     * @request POST:/api/Branches/GetAllItemsDynamic
     * @secure
     */
    branchesGetAllItemsDynamicCreate: (data: IQueryOption, params: RequestParams = {}) =>
      this.request<IBranchDynamicResponse, any>({
        path: `/api/Branches/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Branches
     * @name BranchesCreateCreate
     * @request POST:/api/Branches/Create
     * @secure
     */
    branchesCreateCreate: (data: IBranch, params: RequestParams = {}) =>
      this.request<IBranch, any>({
        path: `/api/Branches/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Branches
     * @name BranchesPatchPartialUpdate
     * @request PATCH:/api/Branches/Patch/{id}
     * @secure
     */
    branchesPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<IBranch, any>({
        path: `/api/Branches/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DataETLs
     * @name DataEtLsDeleteDelete
     * @request DELETE:/api/DataETLs/Delete/{id}
     * @secure
     */
    dataEtLsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IDataETL, any>({
        path: `/api/DataETLs/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DataETLs
     * @name DataEtLsGetAllDynamicList
     * @request GET:/api/DataETLs/GetAllDynamic
     * @secure
     */
    dataEtLsGetAllDynamicList: (
      query: IDataEtLsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IDataETLDynamicResponse, any>({
        path: `/api/DataETLs/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DataETLs
     * @name DataEtLsGetAllItemsDynamicCreate
     * @request POST:/api/DataETLs/GetAllItemsDynamic
     * @secure
     */
    dataEtLsGetAllItemsDynamicCreate: (data: IQueryOption, params: RequestParams = {}) =>
      this.request<IDataETLDynamicResponse, any>({
        path: `/api/DataETLs/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DataETLs
     * @name DataEtLsCreateCreate
     * @request POST:/api/DataETLs/Create
     * @secure
     */
    dataEtLsCreateCreate: (data: IDataETL, params: RequestParams = {}) =>
      this.request<IDataETL, any>({
        path: `/api/DataETLs/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags DataETLs
     * @name DataEtLsPatchPartialUpdate
     * @request PATCH:/api/DataETLs/Patch/{id}
     * @secure
     */
    dataEtLsPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<IDataETL, any>({
        path: `/api/DataETLs/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EquityTypes
     * @name EquityTypesDeleteDelete
     * @request DELETE:/api/EquityTypes/Delete/{id}
     * @secure
     */
    equityTypesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IEquityType, any>({
        path: `/api/EquityTypes/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EquityTypes
     * @name EquityTypesGetAllDynamicList
     * @request GET:/api/EquityTypes/GetAllDynamic
     * @secure
     */
    equityTypesGetAllDynamicList: (
      query: IEquityTypesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IEquityTypeDynamicResponse, any>({
        path: `/api/EquityTypes/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EquityTypes
     * @name EquityTypesGetAllItemsDynamicCreate
     * @request POST:/api/EquityTypes/GetAllItemsDynamic
     * @secure
     */
    equityTypesGetAllItemsDynamicCreate: (data: IQueryOption, params: RequestParams = {}) =>
      this.request<IEquityTypeDynamicResponse, any>({
        path: `/api/EquityTypes/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EquityTypes
     * @name EquityTypesCreateCreate
     * @request POST:/api/EquityTypes/Create
     * @secure
     */
    equityTypesCreateCreate: (data: IEquityType, params: RequestParams = {}) =>
      this.request<IEquityType, any>({
        path: `/api/EquityTypes/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags EquityTypes
     * @name EquityTypesPatchPartialUpdate
     * @request PATCH:/api/EquityTypes/Patch/{id}
     * @secure
     */
    equityTypesPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<IEquityType, any>({
        path: `/api/EquityTypes/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GlobalParameters
     * @name GlobalParametersDeleteDelete
     * @request DELETE:/api/GlobalParameters/Delete/{id}
     * @secure
     */
    globalParametersDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IGlobalParameter, any>({
        path: `/api/GlobalParameters/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GlobalParameters
     * @name GlobalParametersGetAllDynamicList
     * @request GET:/api/GlobalParameters/GetAllDynamic
     * @secure
     */
    globalParametersGetAllDynamicList: (
      query: IGlobalParametersGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IGlobalParameterDynamicResponse, any>({
        path: `/api/GlobalParameters/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GlobalParameters
     * @name GlobalParametersGetAllItemsDynamicCreate
     * @request POST:/api/GlobalParameters/GetAllItemsDynamic
     * @secure
     */
    globalParametersGetAllItemsDynamicCreate: (data: IQueryOption, params: RequestParams = {}) =>
      this.request<IGlobalParameterDynamicResponse, any>({
        path: `/api/GlobalParameters/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GlobalParameters
     * @name GlobalParametersCreateCreate
     * @request POST:/api/GlobalParameters/Create
     * @secure
     */
    globalParametersCreateCreate: (data: IGlobalParameter, params: RequestParams = {}) =>
      this.request<IGlobalParameter, any>({
        path: `/api/GlobalParameters/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GlobalParameters
     * @name GlobalParametersPatchPartialUpdate
     * @request PATCH:/api/GlobalParameters/Patch/{id}
     * @secure
     */
    globalParametersPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<IGlobalParameter, any>({
        path: `/api/GlobalParameters/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GtoEndpoints
     * @name GtoEndpointsDeleteDelete
     * @request DELETE:/api/GtoEndpoints/Delete/{id}
     * @secure
     */
    gtoEndpointsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IGtoEndpoint, any>({
        path: `/api/GtoEndpoints/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GtoEndpoints
     * @name GtoEndpointsGetAllDynamicList
     * @request GET:/api/GtoEndpoints/GetAllDynamic
     * @secure
     */
    gtoEndpointsGetAllDynamicList: (
      query: IGtoEndpointsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IGtoEndpointDynamicResponse, any>({
        path: `/api/GtoEndpoints/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GtoEndpoints
     * @name GtoEndpointsGetAllItemsDynamicCreate
     * @request POST:/api/GtoEndpoints/GetAllItemsDynamic
     * @secure
     */
    gtoEndpointsGetAllItemsDynamicCreate: (data: IQueryOption, params: RequestParams = {}) =>
      this.request<IGtoEndpointDynamicResponse, any>({
        path: `/api/GtoEndpoints/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GtoEndpoints
     * @name GtoEndpointsCreateCreate
     * @request POST:/api/GtoEndpoints/Create
     * @secure
     */
    gtoEndpointsCreateCreate: (data: IGtoEndpoint, params: RequestParams = {}) =>
      this.request<IGtoEndpoint, any>({
        path: `/api/GtoEndpoints/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GtoEndpoints
     * @name GtoEndpointsPatchPartialUpdate
     * @request PATCH:/api/GtoEndpoints/Patch/{id}
     * @secure
     */
    gtoEndpointsPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<IGtoEndpoint, any>({
        path: `/api/GtoEndpoints/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags GtoGatewayHelper
     * @name GtoGatewayHelperTestItOutCreate
     * @request POST:/api/GtoGatewayHelper/TestItOut
     * @secure
     */
    gtoGatewayHelperTestItOutCreate: (data: IGtoRequest, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/GtoGatewayHelper/TestItOut`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Issuers
     * @name IssuersDeleteDelete
     * @request DELETE:/api/Issuers/Delete/{id}
     * @secure
     */
    issuersDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IIssuer, any>({
        path: `/api/Issuers/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Issuers
     * @name IssuersGetAllDynamicList
     * @request GET:/api/Issuers/GetAllDynamic
     * @secure
     */
    issuersGetAllDynamicList: (
      query: IIssuersGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IIssuerDynamicResponse, any>({
        path: `/api/Issuers/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Issuers
     * @name IssuersGetAllItemsDynamicCreate
     * @request POST:/api/Issuers/GetAllItemsDynamic
     * @secure
     */
    issuersGetAllItemsDynamicCreate: (data: IQueryOption, params: RequestParams = {}) =>
      this.request<IIssuerDynamicResponse, any>({
        path: `/api/Issuers/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Issuers
     * @name IssuersCreateCreate
     * @request POST:/api/Issuers/Create
     * @secure
     */
    issuersCreateCreate: (data: IIssuer, params: RequestParams = {}) =>
      this.request<IIssuer, any>({
        path: `/api/Issuers/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Issuers
     * @name IssuersPatchPartialUpdate
     * @request PATCH:/api/Issuers/Patch/{id}
     * @secure
     */
    issuersPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<IIssuer, any>({
        path: `/api/Issuers/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Labels
     * @name LabelsDeleteDelete
     * @request DELETE:/api/Labels/Delete/{id}
     * @secure
     */
    labelsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ILabel, any>({
        path: `/api/Labels/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Labels
     * @name LabelsGetAllDynamicList
     * @request GET:/api/Labels/GetAllDynamic
     * @secure
     */
    labelsGetAllDynamicList: (query: ILabelsGetAllDynamicListParams, params: RequestParams = {}) =>
      this.request<ILabelDynamicResponse, any>({
        path: `/api/Labels/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Labels
     * @name LabelsGetAllItemsDynamicCreate
     * @request POST:/api/Labels/GetAllItemsDynamic
     * @secure
     */
    labelsGetAllItemsDynamicCreate: (data: IQueryOption, params: RequestParams = {}) =>
      this.request<ILabelDynamicResponse, any>({
        path: `/api/Labels/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Labels
     * @name LabelsCreateCreate
     * @request POST:/api/Labels/Create
     * @secure
     */
    labelsCreateCreate: (data: ILabel, params: RequestParams = {}) =>
      this.request<ILabel, any>({
        path: `/api/Labels/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Labels
     * @name LabelsPatchPartialUpdate
     * @request PATCH:/api/Labels/Patch/{id}
     * @secure
     */
    labelsPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<ILabel, any>({
        path: `/api/Labels/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags LabelSources
     * @name LabelSourcesDeleteDelete
     * @request DELETE:/api/LabelSources/Delete/{id}
     * @secure
     */
    labelSourcesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ILabelSource, any>({
        path: `/api/LabelSources/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags LabelSources
     * @name LabelSourcesGetAllDynamicList
     * @request GET:/api/LabelSources/GetAllDynamic
     * @secure
     */
    labelSourcesGetAllDynamicList: (
      query: ILabelSourcesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ILabelSourceDynamicResponse, any>({
        path: `/api/LabelSources/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags LabelSources
     * @name LabelSourcesGetAllItemsDynamicCreate
     * @request POST:/api/LabelSources/GetAllItemsDynamic
     * @secure
     */
    labelSourcesGetAllItemsDynamicCreate: (data: IQueryOption, params: RequestParams = {}) =>
      this.request<ILabelSourceDynamicResponse, any>({
        path: `/api/LabelSources/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags LabelSources
     * @name LabelSourcesCreateCreate
     * @request POST:/api/LabelSources/Create
     * @secure
     */
    labelSourcesCreateCreate: (data: ILabelSource, params: RequestParams = {}) =>
      this.request<ILabelSource, any>({
        path: `/api/LabelSources/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags LabelSources
     * @name LabelSourcesPatchPartialUpdate
     * @request PATCH:/api/LabelSources/Patch/{id}
     * @secure
     */
    labelSourcesPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<ILabelSource, any>({
        path: `/api/LabelSources/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Languages
     * @name LanguagesDeleteDelete
     * @request DELETE:/api/Languages/Delete/{id}
     * @secure
     */
    languagesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ILanguage, any>({
        path: `/api/Languages/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Languages
     * @name LanguagesGetAllDynamicList
     * @request GET:/api/Languages/GetAllDynamic
     * @secure
     */
    languagesGetAllDynamicList: (
      query: ILanguagesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ILanguageDynamicResponse, any>({
        path: `/api/Languages/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Languages
     * @name LanguagesGetAllItemsDynamicCreate
     * @request POST:/api/Languages/GetAllItemsDynamic
     * @secure
     */
    languagesGetAllItemsDynamicCreate: (data: IQueryOption, params: RequestParams = {}) =>
      this.request<ILanguageDynamicResponse, any>({
        path: `/api/Languages/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Languages
     * @name LanguagesCreateCreate
     * @request POST:/api/Languages/Create
     * @secure
     */
    languagesCreateCreate: (data: ILanguage, params: RequestParams = {}) =>
      this.request<ILanguage, any>({
        path: `/api/Languages/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Languages
     * @name LanguagesPatchPartialUpdate
     * @request PATCH:/api/Languages/Patch/{id}
     * @secure
     */
    languagesPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<ILanguage, any>({
        path: `/api/Languages/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Languages
     * @name LanguagesGetDefaultLanguageList
     * @request GET:/api/Languages/GetDefaultLanguage
     * @secure
     */
    languagesGetDefaultLanguageList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Languages/GetDefaultLanguage`,
        method: 'GET',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags LinkedAssets
     * @name LinkedAssetsDeleteDelete
     * @request DELETE:/api/LinkedAssets/Delete/{id}
     * @secure
     */
    linkedAssetsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ILinkedAsset, any>({
        path: `/api/LinkedAssets/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags LinkedAssets
     * @name LinkedAssetsGetAllDynamicList
     * @request GET:/api/LinkedAssets/GetAllDynamic
     * @secure
     */
    linkedAssetsGetAllDynamicList: (
      query: ILinkedAssetsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ILinkedAssetDynamicResponse, any>({
        path: `/api/LinkedAssets/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags LinkedAssets
     * @name LinkedAssetsGetAllItemsDynamicCreate
     * @request POST:/api/LinkedAssets/GetAllItemsDynamic
     * @secure
     */
    linkedAssetsGetAllItemsDynamicCreate: (data: IQueryOption, params: RequestParams = {}) =>
      this.request<ILinkedAssetDynamicResponse, any>({
        path: `/api/LinkedAssets/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags LinkedAssets
     * @name LinkedAssetsCreateCreate
     * @request POST:/api/LinkedAssets/Create
     * @secure
     */
    linkedAssetsCreateCreate: (data: ILinkedAsset, params: RequestParams = {}) =>
      this.request<ILinkedAsset, any>({
        path: `/api/LinkedAssets/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags LinkedAssets
     * @name LinkedAssetsPatchPartialUpdate
     * @request PATCH:/api/LinkedAssets/Patch/{id}
     * @secure
     */
    linkedAssetsPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<ILinkedAsset, any>({
        path: `/api/LinkedAssets/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags LogSources
     * @name LogSourcesDeleteDelete
     * @request DELETE:/api/LogSources/Delete/{id}
     * @secure
     */
    logSourcesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ILogSource, any>({
        path: `/api/LogSources/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags LogSources
     * @name LogSourcesGetAllDynamicList
     * @request GET:/api/LogSources/GetAllDynamic
     * @secure
     */
    logSourcesGetAllDynamicList: (
      query: ILogSourcesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ILogSourceDynamicResponse, any>({
        path: `/api/LogSources/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags LogSources
     * @name LogSourcesGetAllItemsDynamicCreate
     * @request POST:/api/LogSources/GetAllItemsDynamic
     * @secure
     */
    logSourcesGetAllItemsDynamicCreate: (data: IQueryOption, params: RequestParams = {}) =>
      this.request<ILogSourceDynamicResponse, any>({
        path: `/api/LogSources/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags LogSources
     * @name LogSourcesCreateCreate
     * @request POST:/api/LogSources/Create
     * @secure
     */
    logSourcesCreateCreate: (data: ILogSource, params: RequestParams = {}) =>
      this.request<ILogSource, any>({
        path: `/api/LogSources/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags LogSources
     * @name LogSourcesPatchPartialUpdate
     * @request PATCH:/api/LogSources/Patch/{id}
     * @secure
     */
    logSourcesPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<ILogSource, any>({
        path: `/api/LogSources/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags LogTypes
     * @name LogTypesDeleteDelete
     * @request DELETE:/api/LogTypes/Delete/{id}
     * @secure
     */
    logTypesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ILogType, any>({
        path: `/api/LogTypes/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags LogTypes
     * @name LogTypesGetAllDynamicList
     * @request GET:/api/LogTypes/GetAllDynamic
     * @secure
     */
    logTypesGetAllDynamicList: (
      query: ILogTypesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ILogTypeDynamicResponse, any>({
        path: `/api/LogTypes/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags LogTypes
     * @name LogTypesGetAllItemsDynamicCreate
     * @request POST:/api/LogTypes/GetAllItemsDynamic
     * @secure
     */
    logTypesGetAllItemsDynamicCreate: (data: IQueryOption, params: RequestParams = {}) =>
      this.request<ILogTypeDynamicResponse, any>({
        path: `/api/LogTypes/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags LogTypes
     * @name LogTypesCreateCreate
     * @request POST:/api/LogTypes/Create
     * @secure
     */
    logTypesCreateCreate: (data: ILogType, params: RequestParams = {}) =>
      this.request<ILogType, any>({
        path: `/api/LogTypes/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags LogTypes
     * @name LogTypesPatchPartialUpdate
     * @request PATCH:/api/LogTypes/Patch/{id}
     * @secure
     */
    logTypesPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<ILogType, any>({
        path: `/api/LogTypes/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MaalotRatingArchives
     * @name MaalotRatingArchivesGetAllDynamicList
     * @request GET:/api/MaalotRatingArchives/GetAllDynamic
     * @secure
     */
    maalotRatingArchivesGetAllDynamicList: (
      query: IMaalotRatingArchivesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IMaalotRatingArchiveDynamicResponse, any>({
        path: `/api/MaalotRatingArchives/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MaalotRatingArchives
     * @name MaalotRatingArchivesGetAllItemsDynamicCreate
     * @request POST:/api/MaalotRatingArchives/GetAllItemsDynamic
     * @secure
     */
    maalotRatingArchivesGetAllItemsDynamicCreate: (
      data: IQueryOption,
      params: RequestParams = {},
    ) =>
      this.request<IMaalotRatingArchiveDynamicResponse, any>({
        path: `/api/MaalotRatingArchives/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MaalotRatings
     * @name MaalotRatingsDeleteDelete
     * @request DELETE:/api/MaalotRatings/Delete/{id}
     * @secure
     */
    maalotRatingsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IMaalotRating, any>({
        path: `/api/MaalotRatings/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MaalotRatings
     * @name MaalotRatingsGetAllDynamicList
     * @request GET:/api/MaalotRatings/GetAllDynamic
     * @secure
     */
    maalotRatingsGetAllDynamicList: (
      query: IMaalotRatingsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IMaalotRatingDynamicResponse, any>({
        path: `/api/MaalotRatings/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MaalotRatings
     * @name MaalotRatingsGetAllItemsDynamicCreate
     * @request POST:/api/MaalotRatings/GetAllItemsDynamic
     * @secure
     */
    maalotRatingsGetAllItemsDynamicCreate: (data: IQueryOption, params: RequestParams = {}) =>
      this.request<IMaalotRatingDynamicResponse, any>({
        path: `/api/MaalotRatings/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MaalotRatings
     * @name MaalotRatingsCreateCreate
     * @request POST:/api/MaalotRatings/Create
     * @secure
     */
    maalotRatingsCreateCreate: (data: IMaalotRating, params: RequestParams = {}) =>
      this.request<IMaalotRating, any>({
        path: `/api/MaalotRatings/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MaalotRatings
     * @name MaalotRatingsPatchPartialUpdate
     * @request PATCH:/api/MaalotRatings/Patch/{id}
     * @secure
     */
    maalotRatingsPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<IMaalotRating, any>({
        path: `/api/MaalotRatings/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MediaUploads
     * @name MediaUploadsUploadFileToCloudCreate
     * @request POST:/api/MediaUploads/UploadFileToCloud
     * @secure
     */
    mediaUploadsUploadFileToCloudCreate: (data: ICloudFileViewModel, params: RequestParams = {}) =>
      this.request<ICloudFileViewModel, any>({
        path: `/api/MediaUploads/UploadFileToCloud`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MediaUploads
     * @name MediaUploadsRemoveFileFromCloudUpdate
     * @request PUT:/api/MediaUploads/RemoveFileFromCloud
     * @secure
     */
    mediaUploadsRemoveFileFromCloudUpdate: (
      query: IMediaUploadsRemoveFileFromCloudUpdateParams,
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/api/MediaUploads/RemoveFileFromCloud`,
        method: 'PUT',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MediaUploads
     * @name MediaUploadsDownloadFileFromCloudList
     * @request GET:/api/MediaUploads/DownloadFileFromCloud
     * @secure
     */
    mediaUploadsDownloadFileFromCloudList: (
      query: IMediaUploadsDownloadFileFromCloudListParams,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/MediaUploads/DownloadFileFromCloud`,
        method: 'GET',
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MidroogRatingArchives
     * @name MidroogRatingArchivesGetAllDynamicList
     * @request GET:/api/MidroogRatingArchives/GetAllDynamic
     * @secure
     */
    midroogRatingArchivesGetAllDynamicList: (
      query: IMidroogRatingArchivesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IMidroogRatingArchiveDynamicResponse, any>({
        path: `/api/MidroogRatingArchives/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MidroogRatingArchives
     * @name MidroogRatingArchivesGetAllItemsDynamicCreate
     * @request POST:/api/MidroogRatingArchives/GetAllItemsDynamic
     * @secure
     */
    midroogRatingArchivesGetAllItemsDynamicCreate: (
      data: IQueryOption,
      params: RequestParams = {},
    ) =>
      this.request<IMidroogRatingArchiveDynamicResponse, any>({
        path: `/api/MidroogRatingArchives/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MidroogRatings
     * @name MidroogRatingsDeleteDelete
     * @request DELETE:/api/MidroogRatings/Delete/{id}
     * @secure
     */
    midroogRatingsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IMidroogRating, any>({
        path: `/api/MidroogRatings/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MidroogRatings
     * @name MidroogRatingsGetAllDynamicList
     * @request GET:/api/MidroogRatings/GetAllDynamic
     * @secure
     */
    midroogRatingsGetAllDynamicList: (
      query: IMidroogRatingsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IMidroogRatingDynamicResponse, any>({
        path: `/api/MidroogRatings/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MidroogRatings
     * @name MidroogRatingsGetAllItemsDynamicCreate
     * @request POST:/api/MidroogRatings/GetAllItemsDynamic
     * @secure
     */
    midroogRatingsGetAllItemsDynamicCreate: (data: IQueryOption, params: RequestParams = {}) =>
      this.request<IMidroogRatingDynamicResponse, any>({
        path: `/api/MidroogRatings/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MidroogRatings
     * @name MidroogRatingsCreateCreate
     * @request POST:/api/MidroogRatings/Create
     * @secure
     */
    midroogRatingsCreateCreate: (data: IMidroogRating, params: RequestParams = {}) =>
      this.request<IMidroogRating, any>({
        path: `/api/MidroogRatings/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags MidroogRatings
     * @name MidroogRatingsPatchPartialUpdate
     * @request PATCH:/api/MidroogRatings/Patch/{id}
     * @secure
     */
    midroogRatingsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<IMidroogRating, any>({
        path: `/api/MidroogRatings/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Migrations
     * @name MigrationsGetMissingDatesList
     * @request GET:/api/Migrations/GetMissingDates
     * @secure
     */
    migrationsGetMissingDatesList: (params: RequestParams = {}) =>
      this.request<IDataMissingDate[], any>({
        path: `/api/Migrations/GetMissingDates`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Migrations
     * @name MigrationsMoveUsersList
     * @request GET:/api/Migrations/MoveUsers
     * @secure
     */
    migrationsMoveUsersList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Migrations/MoveUsers`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Migrations
     * @name MigrationsMoveLogsList
     * @request GET:/api/Migrations/MoveLogs
     * @secure
     */
    migrationsMoveLogsList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Migrations/MoveLogs`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Migrations
     * @name MigrationsUpdateLogsList
     * @request GET:/api/Migrations/UpdateLogs
     * @secure
     */
    migrationsUpdateLogsList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Migrations/UpdateLogs`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Migrations
     * @name MigrationsMoveRatingsList
     * @request GET:/api/Migrations/MoveRatings
     * @secure
     */
    migrationsMoveRatingsList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Migrations/MoveRatings`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Migrations
     * @name MigrationsMoveBondParametersList
     * @request GET:/api/Migrations/MoveBondParameters
     * @secure
     */
    migrationsMoveBondParametersList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Migrations/MoveBondParameters`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Migrations
     * @name MigrationsMoveBondGelemsList
     * @request GET:/api/Migrations/MoveBondGelems
     * @secure
     */
    migrationsMoveBondGelemsList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Migrations/MoveBondGelems`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Migrations
     * @name MigrationsMoveBondsList
     * @request GET:/api/Migrations/MoveBonds
     * @secure
     */
    migrationsMoveBondsList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Migrations/MoveBonds`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Migrations
     * @name MigrationsMoveStockParametersList
     * @request GET:/api/Migrations/MoveStockParameters
     * @secure
     */
    migrationsMoveStockParametersList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Migrations/MoveStockParameters`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Migrations
     * @name MigrationsMoveStockGelemsList
     * @request GET:/api/Migrations/MoveStockGelems
     * @secure
     */
    migrationsMoveStockGelemsList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Migrations/MoveStockGelems`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Migrations
     * @name MigrationsMoveStocksList
     * @request GET:/api/Migrations/MoveStocks
     * @secure
     */
    migrationsMoveStocksList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Migrations/MoveStocks`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Migrations
     * @name MigrationsMoveUserProfileBondDefaultAlertsList
     * @request GET:/api/Migrations/MoveUserProfileBondDefaultAlerts
     * @secure
     */
    migrationsMoveUserProfileBondDefaultAlertsList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Migrations/MoveUserProfileBondDefaultAlerts`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Migrations
     * @name MigrationsMoveUserProfileBondsList
     * @request GET:/api/Migrations/MoveUserProfileBonds
     * @secure
     */
    migrationsMoveUserProfileBondsList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Migrations/MoveUserProfileBonds`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Migrations
     * @name MigrationsMoveUserProfileStockDefaultAlertsList
     * @request GET:/api/Migrations/MoveUserProfileStockDefaultAlerts
     * @secure
     */
    migrationsMoveUserProfileStockDefaultAlertsList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Migrations/MoveUserProfileStockDefaultAlerts`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Migrations
     * @name MigrationsMoveUserProfileStocksList
     * @request GET:/api/Migrations/MoveUserProfileStocks
     * @secure
     */
    migrationsMoveUserProfileStocksList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Migrations/MoveUserProfileStocks`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Migrations
     * @name MigrationsMoveUserProfileHistAlertsList
     * @request GET:/api/Migrations/MoveUserProfileHistAlerts
     * @secure
     */
    migrationsMoveUserProfileHistAlertsList: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/Migrations/MoveUserProfileHistAlerts`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StockArchives
     * @name StockArchivesGetAllDynamicList
     * @request GET:/api/StockArchives/GetAllDynamic
     * @secure
     */
    stockArchivesGetAllDynamicList: (
      query: IStockArchivesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IStockArchiveDynamicResponse, any>({
        path: `/api/StockArchives/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StockArchives
     * @name StockArchivesGetAllItemsDynamicCreate
     * @request POST:/api/StockArchives/GetAllItemsDynamic
     * @secure
     */
    stockArchivesGetAllItemsDynamicCreate: (data: IQueryOption, params: RequestParams = {}) =>
      this.request<IStockArchiveDynamicResponse, any>({
        path: `/api/StockArchives/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StockGelemArchives
     * @name StockGelemArchivesGetAllDynamicList
     * @request GET:/api/StockGelemArchives/GetAllDynamic
     * @secure
     */
    stockGelemArchivesGetAllDynamicList: (
      query: IStockGelemArchivesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IStockGelemArchiveDynamicResponse, any>({
        path: `/api/StockGelemArchives/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StockGelemArchives
     * @name StockGelemArchivesGetAllItemsDynamicCreate
     * @request POST:/api/StockGelemArchives/GetAllItemsDynamic
     * @secure
     */
    stockGelemArchivesGetAllItemsDynamicCreate: (data: IQueryOption, params: RequestParams = {}) =>
      this.request<IStockGelemArchiveDynamicResponse, any>({
        path: `/api/StockGelemArchives/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StockGelems
     * @name StockGelemsDeleteDelete
     * @request DELETE:/api/StockGelems/Delete/{id}
     * @secure
     */
    stockGelemsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IStockGelem, any>({
        path: `/api/StockGelems/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StockGelems
     * @name StockGelemsGetAllDynamicList
     * @request GET:/api/StockGelems/GetAllDynamic
     * @secure
     */
    stockGelemsGetAllDynamicList: (
      query: IStockGelemsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IStockGelemDynamicResponse, any>({
        path: `/api/StockGelems/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StockGelems
     * @name StockGelemsGetAllItemsDynamicCreate
     * @request POST:/api/StockGelems/GetAllItemsDynamic
     * @secure
     */
    stockGelemsGetAllItemsDynamicCreate: (data: IQueryOption, params: RequestParams = {}) =>
      this.request<IStockGelemDynamicResponse, any>({
        path: `/api/StockGelems/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StockGelems
     * @name StockGelemsCreateCreate
     * @request POST:/api/StockGelems/Create
     * @secure
     */
    stockGelemsCreateCreate: (data: IStockGelem, params: RequestParams = {}) =>
      this.request<IStockGelem, any>({
        path: `/api/StockGelems/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StockGelems
     * @name StockGelemsPatchPartialUpdate
     * @request PATCH:/api/StockGelems/Patch/{id}
     * @secure
     */
    stockGelemsPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<IStockGelem, any>({
        path: `/api/StockGelems/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StockMarketExludedDates
     * @name StockMarketExludedDatesDeleteDelete
     * @request DELETE:/api/StockMarketExludedDates/Delete/{id}
     * @secure
     */
    stockMarketExludedDatesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IStockMarketExludedDate, any>({
        path: `/api/StockMarketExludedDates/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StockMarketExludedDates
     * @name StockMarketExludedDatesGetAllDynamicList
     * @request GET:/api/StockMarketExludedDates/GetAllDynamic
     * @secure
     */
    stockMarketExludedDatesGetAllDynamicList: (
      query: IStockMarketExludedDatesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IStockMarketExludedDateDynamicResponse, any>({
        path: `/api/StockMarketExludedDates/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StockMarketExludedDates
     * @name StockMarketExludedDatesGetAllItemsDynamicCreate
     * @request POST:/api/StockMarketExludedDates/GetAllItemsDynamic
     * @secure
     */
    stockMarketExludedDatesGetAllItemsDynamicCreate: (
      data: IQueryOption,
      params: RequestParams = {},
    ) =>
      this.request<IStockMarketExludedDateDynamicResponse, any>({
        path: `/api/StockMarketExludedDates/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StockMarketExludedDates
     * @name StockMarketExludedDatesCreateCreate
     * @request POST:/api/StockMarketExludedDates/Create
     * @secure
     */
    stockMarketExludedDatesCreateCreate: (
      data: IStockMarketExludedDate,
      params: RequestParams = {},
    ) =>
      this.request<IStockMarketExludedDate, any>({
        path: `/api/StockMarketExludedDates/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StockMarketExludedDates
     * @name StockMarketExludedDatesPatchPartialUpdate
     * @request PATCH:/api/StockMarketExludedDates/Patch/{id}
     * @secure
     */
    stockMarketExludedDatesPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<IStockMarketExludedDate, any>({
        path: `/api/StockMarketExludedDates/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StockOnlines
     * @name StockOnlinesDeleteDelete
     * @request DELETE:/api/StockOnlines/Delete/{id}
     * @secure
     */
    stockOnlinesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IStockOnline, any>({
        path: `/api/StockOnlines/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StockOnlines
     * @name StockOnlinesGetAllDynamicList
     * @request GET:/api/StockOnlines/GetAllDynamic
     * @secure
     */
    stockOnlinesGetAllDynamicList: (
      query: IStockOnlinesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IStockOnlineDynamicResponse, any>({
        path: `/api/StockOnlines/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StockOnlines
     * @name StockOnlinesGetAllItemsDynamicCreate
     * @request POST:/api/StockOnlines/GetAllItemsDynamic
     * @secure
     */
    stockOnlinesGetAllItemsDynamicCreate: (data: IQueryOption, params: RequestParams = {}) =>
      this.request<IStockOnlineDynamicResponse, any>({
        path: `/api/StockOnlines/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StockOnlines
     * @name StockOnlinesCreateCreate
     * @request POST:/api/StockOnlines/Create
     * @secure
     */
    stockOnlinesCreateCreate: (data: IStockOnline, params: RequestParams = {}) =>
      this.request<IStockOnline, any>({
        path: `/api/StockOnlines/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StockOnlines
     * @name StockOnlinesPatchPartialUpdate
     * @request PATCH:/api/StockOnlines/Patch/{id}
     * @secure
     */
    stockOnlinesPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<IStockOnline, any>({
        path: `/api/StockOnlines/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stocks
     * @name StocksDeleteDelete
     * @request DELETE:/api/Stocks/Delete/{id}
     * @secure
     */
    stocksDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IStock, any>({
        path: `/api/Stocks/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stocks
     * @name StocksGetAllDynamicList
     * @request GET:/api/Stocks/GetAllDynamic
     * @secure
     */
    stocksGetAllDynamicList: (query: IStocksGetAllDynamicListParams, params: RequestParams = {}) =>
      this.request<IStockDynamicResponse, any>({
        path: `/api/Stocks/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stocks
     * @name StocksGetAllItemsDynamicCreate
     * @request POST:/api/Stocks/GetAllItemsDynamic
     * @secure
     */
    stocksGetAllItemsDynamicCreate: (data: IQueryOption, params: RequestParams = {}) =>
      this.request<IStockDynamicResponse, any>({
        path: `/api/Stocks/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stocks
     * @name StocksCreateCreate
     * @request POST:/api/Stocks/Create
     * @secure
     */
    stocksCreateCreate: (data: IStock, params: RequestParams = {}) =>
      this.request<IStock, any>({
        path: `/api/Stocks/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Stocks
     * @name StocksPatchPartialUpdate
     * @request PATCH:/api/Stocks/Patch/{id}
     * @secure
     */
    stocksPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<IStock, any>({
        path: `/api/Stocks/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StocksHelper
     * @name StocksHelperGetUserStocksDynamicList
     * @request GET:/api/StocksHelper/GetUserStocksDynamic
     * @secure
     */
    stocksHelperGetUserStocksDynamicList: (
      query: IStocksHelperGetUserStocksDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IDataUserStockDynamicResponse, any>({
        path: `/api/StocksHelper/GetUserStocksDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StocksHelper
     * @name StocksHelperGetUserStockItemsDynamicCreate
     * @request POST:/api/StocksHelper/GetUserStockItemsDynamic
     * @secure
     */
    stocksHelperGetUserStockItemsDynamicCreate: (
      data: IUserQueryOption,
      params: RequestParams = {},
    ) =>
      this.request<IDataUserStockDynamicResponse, any>({
        path: `/api/StocksHelper/GetUserStockItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StocksHelper
     * @name StocksHelperGetSystemStocksDynamicList
     * @request GET:/api/StocksHelper/GetSystemStocksDynamic
     * @secure
     */
    stocksHelperGetSystemStocksDynamicList: (
      query: IStocksHelperGetSystemStocksDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IDataStockDynamicResponse, any>({
        path: `/api/StocksHelper/GetSystemStocksDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StocksHelper
     * @name StocksHelperGetSystemStockItemsDynamicCreate
     * @request POST:/api/StocksHelper/GetSystemStockItemsDynamic
     * @secure
     */
    stocksHelperGetSystemStockItemsDynamicCreate: (
      data: IUserQueryOption,
      params: RequestParams = {},
    ) =>
      this.request<IDataStockDynamicResponse, any>({
        path: `/api/StocksHelper/GetSystemStockItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StocksHelper
     * @name StocksHelperInsertNewUserStocksAlertsCreate
     * @request POST:/api/StocksHelper/InsertNewUserStocksAlerts
     * @secure
     */
    stocksHelperInsertNewUserStocksAlertsCreate: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/StocksHelper/InsertNewUserStocksAlerts`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StocksHelper
     * @name StocksHelperDeleteUserStocksAlertsDelete
     * @request DELETE:/api/StocksHelper/DeleteUserStocksAlerts
     * @secure
     */
    stocksHelperDeleteUserStocksAlertsDelete: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/StocksHelper/DeleteUserStocksAlerts`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StocksHelper
     * @name StocksHelperResetUserStocksAlertsToUserDefaultUpdate
     * @request PUT:/api/StocksHelper/ResetUserStocksAlertsToUserDefault
     * @secure
     */
    stocksHelperResetUserStocksAlertsToUserDefaultUpdate: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/StocksHelper/ResetUserStocksAlertsToUserDefault`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StocksHelper
     * @name StocksHelperResetUserStocksAlertsToSystemDefaultUpdate
     * @request PUT:/api/StocksHelper/ResetUserStocksAlertsToSystemDefault
     * @secure
     */
    stocksHelperResetUserStocksAlertsToSystemDefaultUpdate: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/StocksHelper/ResetUserStocksAlertsToSystemDefault`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StocksHelper
     * @name StocksHelperLoadUserStocksAlertsBySymbolsCreate
     * @request POST:/api/StocksHelper/LoadUserStocksAlertsBySymbols
     * @secure
     */
    stocksHelperLoadUserStocksAlertsBySymbolsCreate: (data: string[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/StocksHelper/LoadUserStocksAlertsBySymbols`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StocksHelper
     * @name StocksHelperLoadUserStocksAlertsByIssuersCreate
     * @request POST:/api/StocksHelper/LoadUserStocksAlertsByIssuers
     * @secure
     */
    stocksHelperLoadUserStocksAlertsByIssuersCreate: (data: string[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/StocksHelper/LoadUserStocksAlertsByIssuers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StocksHelper
     * @name StocksHelperLoadUserStocksAlertsByIDsCreate
     * @request POST:/api/StocksHelper/LoadUserStocksAlertsByIDs
     * @secure
     */
    stocksHelperLoadUserStocksAlertsByIDsCreate: (data: string[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/StocksHelper/LoadUserStocksAlertsByIDs`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags StocksHelper
     * @name StocksHelperUnLoadUserStocksAlertsByIDsCreate
     * @request POST:/api/StocksHelper/UnLoadUserStocksAlertsByIDs
     * @secure
     */
    stocksHelperUnLoadUserStocksAlertsByIDsCreate: (data: string[], params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/api/StocksHelper/UnLoadUserStocksAlertsByIDs`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemBondDefaultAlertArchives
     * @name SystemBondDefaultAlertArchivesGetAllDynamicList
     * @request GET:/api/SystemBondDefaultAlertArchives/GetAllDynamic
     * @secure
     */
    systemBondDefaultAlertArchivesGetAllDynamicList: (
      query: ISystemBondDefaultAlertArchivesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ISystemBondDefaultAlertArchiveDynamicResponse, any>({
        path: `/api/SystemBondDefaultAlertArchives/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemBondDefaultAlertArchives
     * @name SystemBondDefaultAlertArchivesGetAllItemsDynamicCreate
     * @request POST:/api/SystemBondDefaultAlertArchives/GetAllItemsDynamic
     * @secure
     */
    systemBondDefaultAlertArchivesGetAllItemsDynamicCreate: (
      data: IQueryOption,
      params: RequestParams = {},
    ) =>
      this.request<ISystemBondDefaultAlertArchiveDynamicResponse, any>({
        path: `/api/SystemBondDefaultAlertArchives/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemBondDefaultAlerts
     * @name SystemBondDefaultAlertsDeleteDelete
     * @request DELETE:/api/SystemBondDefaultAlerts/Delete/{id}
     * @secure
     */
    systemBondDefaultAlertsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ISystemBondDefaultAlert, any>({
        path: `/api/SystemBondDefaultAlerts/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemBondDefaultAlerts
     * @name SystemBondDefaultAlertsGetAllDynamicList
     * @request GET:/api/SystemBondDefaultAlerts/GetAllDynamic
     * @secure
     */
    systemBondDefaultAlertsGetAllDynamicList: (
      query: ISystemBondDefaultAlertsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ISystemBondDefaultAlertDynamicResponse, any>({
        path: `/api/SystemBondDefaultAlerts/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemBondDefaultAlerts
     * @name SystemBondDefaultAlertsGetAllItemsDynamicCreate
     * @request POST:/api/SystemBondDefaultAlerts/GetAllItemsDynamic
     * @secure
     */
    systemBondDefaultAlertsGetAllItemsDynamicCreate: (
      data: IQueryOption,
      params: RequestParams = {},
    ) =>
      this.request<ISystemBondDefaultAlertDynamicResponse, any>({
        path: `/api/SystemBondDefaultAlerts/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemBondDefaultAlerts
     * @name SystemBondDefaultAlertsCreateCreate
     * @request POST:/api/SystemBondDefaultAlerts/Create
     * @secure
     */
    systemBondDefaultAlertsCreateCreate: (
      data: ISystemBondDefaultAlert,
      params: RequestParams = {},
    ) =>
      this.request<ISystemBondDefaultAlert, any>({
        path: `/api/SystemBondDefaultAlerts/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemBondDefaultAlerts
     * @name SystemBondDefaultAlertsPatchPartialUpdate
     * @request PATCH:/api/SystemBondDefaultAlerts/Patch/{id}
     * @secure
     */
    systemBondDefaultAlertsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<ISystemBondDefaultAlert, any>({
        path: `/api/SystemBondDefaultAlerts/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemStockDefaultAlertArchives
     * @name SystemStockDefaultAlertArchivesGetAllDynamicList
     * @request GET:/api/SystemStockDefaultAlertArchives/GetAllDynamic
     * @secure
     */
    systemStockDefaultAlertArchivesGetAllDynamicList: (
      query: ISystemStockDefaultAlertArchivesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ISystemStockDefaultAlertArchiveDynamicResponse, any>({
        path: `/api/SystemStockDefaultAlertArchives/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemStockDefaultAlertArchives
     * @name SystemStockDefaultAlertArchivesGetAllItemsDynamicCreate
     * @request POST:/api/SystemStockDefaultAlertArchives/GetAllItemsDynamic
     * @secure
     */
    systemStockDefaultAlertArchivesGetAllItemsDynamicCreate: (
      data: IQueryOption,
      params: RequestParams = {},
    ) =>
      this.request<ISystemStockDefaultAlertArchiveDynamicResponse, any>({
        path: `/api/SystemStockDefaultAlertArchives/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemStockDefaultAlerts
     * @name SystemStockDefaultAlertsDeleteDelete
     * @request DELETE:/api/SystemStockDefaultAlerts/Delete/{id}
     * @secure
     */
    systemStockDefaultAlertsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ISystemStockDefaultAlert, any>({
        path: `/api/SystemStockDefaultAlerts/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemStockDefaultAlerts
     * @name SystemStockDefaultAlertsGetAllDynamicList
     * @request GET:/api/SystemStockDefaultAlerts/GetAllDynamic
     * @secure
     */
    systemStockDefaultAlertsGetAllDynamicList: (
      query: ISystemStockDefaultAlertsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ISystemStockDefaultAlertDynamicResponse, any>({
        path: `/api/SystemStockDefaultAlerts/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemStockDefaultAlerts
     * @name SystemStockDefaultAlertsGetAllItemsDynamicCreate
     * @request POST:/api/SystemStockDefaultAlerts/GetAllItemsDynamic
     * @secure
     */
    systemStockDefaultAlertsGetAllItemsDynamicCreate: (
      data: IQueryOption,
      params: RequestParams = {},
    ) =>
      this.request<ISystemStockDefaultAlertDynamicResponse, any>({
        path: `/api/SystemStockDefaultAlerts/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemStockDefaultAlerts
     * @name SystemStockDefaultAlertsCreateCreate
     * @request POST:/api/SystemStockDefaultAlerts/Create
     * @secure
     */
    systemStockDefaultAlertsCreateCreate: (
      data: ISystemStockDefaultAlert,
      params: RequestParams = {},
    ) =>
      this.request<ISystemStockDefaultAlert, any>({
        path: `/api/SystemStockDefaultAlerts/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags SystemStockDefaultAlerts
     * @name SystemStockDefaultAlertsPatchPartialUpdate
     * @request PATCH:/api/SystemStockDefaultAlerts/Patch/{id}
     * @secure
     */
    systemStockDefaultAlertsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<ISystemStockDefaultAlert, any>({
        path: `/api/SystemStockDefaultAlerts/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags TradeStatuses
     * @name TradeStatusesDeleteDelete
     * @request DELETE:/api/TradeStatuses/Delete/{id}
     * @secure
     */
    tradeStatusesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<ITradeStatus, any>({
        path: `/api/TradeStatuses/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags TradeStatuses
     * @name TradeStatusesGetAllDynamicList
     * @request GET:/api/TradeStatuses/GetAllDynamic
     * @secure
     */
    tradeStatusesGetAllDynamicList: (
      query: ITradeStatusesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<ITradeStatusDynamicResponse, any>({
        path: `/api/TradeStatuses/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags TradeStatuses
     * @name TradeStatusesGetAllItemsDynamicCreate
     * @request POST:/api/TradeStatuses/GetAllItemsDynamic
     * @secure
     */
    tradeStatusesGetAllItemsDynamicCreate: (data: IQueryOption, params: RequestParams = {}) =>
      this.request<ITradeStatusDynamicResponse, any>({
        path: `/api/TradeStatuses/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags TradeStatuses
     * @name TradeStatusesCreateCreate
     * @request POST:/api/TradeStatuses/Create
     * @secure
     */
    tradeStatusesCreateCreate: (data: ITradeStatus, params: RequestParams = {}) =>
      this.request<ITradeStatus, any>({
        path: `/api/TradeStatuses/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags TradeStatuses
     * @name TradeStatusesPatchPartialUpdate
     * @request PATCH:/api/TradeStatuses/Patch/{id}
     * @secure
     */
    tradeStatusesPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<ITradeStatus, any>({
        path: `/api/TradeStatuses/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileArchives
     * @name UserProfileArchivesGetAllDynamicList
     * @request GET:/api/UserProfileArchives/GetAllDynamic
     * @secure
     */
    userProfileArchivesGetAllDynamicList: (
      query: IUserProfileArchivesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IUserProfileArchiveDynamicResponse, any>({
        path: `/api/UserProfileArchives/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileArchives
     * @name UserProfileArchivesGetAllItemsDynamicCreate
     * @request POST:/api/UserProfileArchives/GetAllItemsDynamic
     * @secure
     */
    userProfileArchivesGetAllItemsDynamicCreate: (data: IQueryOption, params: RequestParams = {}) =>
      this.request<IUserProfileArchiveDynamicResponse, any>({
        path: `/api/UserProfileArchives/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileBondArchives
     * @name UserProfileBondArchivesGetAllDynamicList
     * @request GET:/api/UserProfileBondArchives/GetAllDynamic
     * @secure
     */
    userProfileBondArchivesGetAllDynamicList: (
      query: IUserProfileBondArchivesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IUserProfileBondArchiveDynamicResponse, any>({
        path: `/api/UserProfileBondArchives/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileBondArchives
     * @name UserProfileBondArchivesGetAllItemsDynamicCreate
     * @request POST:/api/UserProfileBondArchives/GetAllItemsDynamic
     * @secure
     */
    userProfileBondArchivesGetAllItemsDynamicCreate: (
      data: IQueryOption,
      params: RequestParams = {},
    ) =>
      this.request<IUserProfileBondArchiveDynamicResponse, any>({
        path: `/api/UserProfileBondArchives/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileBondDefaultAlertArchives
     * @name UserProfileBondDefaultAlertArchivesGetAllDynamicList
     * @request GET:/api/UserProfileBondDefaultAlertArchives/GetAllDynamic
     * @secure
     */
    userProfileBondDefaultAlertArchivesGetAllDynamicList: (
      query: IUserProfileBondDefaultAlertArchivesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IUserProfileBondDefaultAlertArchiveDynamicResponse, any>({
        path: `/api/UserProfileBondDefaultAlertArchives/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileBondDefaultAlertArchives
     * @name UserProfileBondDefaultAlertArchivesGetAllItemsDynamicCreate
     * @request POST:/api/UserProfileBondDefaultAlertArchives/GetAllItemsDynamic
     * @secure
     */
    userProfileBondDefaultAlertArchivesGetAllItemsDynamicCreate: (
      data: IQueryOption,
      params: RequestParams = {},
    ) =>
      this.request<IUserProfileBondDefaultAlertArchiveDynamicResponse, any>({
        path: `/api/UserProfileBondDefaultAlertArchives/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileBondDefaultAlerts
     * @name UserProfileBondDefaultAlertsDeleteDelete
     * @request DELETE:/api/UserProfileBondDefaultAlerts/Delete/{id}
     * @secure
     */
    userProfileBondDefaultAlertsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IUserProfileBondDefaultAlert, any>({
        path: `/api/UserProfileBondDefaultAlerts/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileBondDefaultAlerts
     * @name UserProfileBondDefaultAlertsGetAllDynamicList
     * @request GET:/api/UserProfileBondDefaultAlerts/GetAllDynamic
     * @secure
     */
    userProfileBondDefaultAlertsGetAllDynamicList: (
      query: IUserProfileBondDefaultAlertsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IUserProfileBondDefaultAlertDynamicResponse, any>({
        path: `/api/UserProfileBondDefaultAlerts/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileBondDefaultAlerts
     * @name UserProfileBondDefaultAlertsGetAllItemsDynamicCreate
     * @request POST:/api/UserProfileBondDefaultAlerts/GetAllItemsDynamic
     * @secure
     */
    userProfileBondDefaultAlertsGetAllItemsDynamicCreate: (
      data: IQueryOption,
      params: RequestParams = {},
    ) =>
      this.request<IUserProfileBondDefaultAlertDynamicResponse, any>({
        path: `/api/UserProfileBondDefaultAlerts/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileBondDefaultAlerts
     * @name UserProfileBondDefaultAlertsCreateCreate
     * @request POST:/api/UserProfileBondDefaultAlerts/Create
     * @secure
     */
    userProfileBondDefaultAlertsCreateCreate: (
      data: IUserProfileBondDefaultAlert,
      params: RequestParams = {},
    ) =>
      this.request<IUserProfileBondDefaultAlert, any>({
        path: `/api/UserProfileBondDefaultAlerts/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileBondDefaultAlerts
     * @name UserProfileBondDefaultAlertsPatchPartialUpdate
     * @request PATCH:/api/UserProfileBondDefaultAlerts/Patch/{id}
     * @secure
     */
    userProfileBondDefaultAlertsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<IUserProfileBondDefaultAlert, any>({
        path: `/api/UserProfileBondDefaultAlerts/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileBonds
     * @name UserProfileBondsDeleteDelete
     * @request DELETE:/api/UserProfileBonds/Delete/{id}
     * @secure
     */
    userProfileBondsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IUserProfileBond, any>({
        path: `/api/UserProfileBonds/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileBonds
     * @name UserProfileBondsGetAllDynamicList
     * @request GET:/api/UserProfileBonds/GetAllDynamic
     * @secure
     */
    userProfileBondsGetAllDynamicList: (
      query: IUserProfileBondsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IUserProfileBondDynamicResponse, any>({
        path: `/api/UserProfileBonds/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileBonds
     * @name UserProfileBondsGetAllItemsDynamicCreate
     * @request POST:/api/UserProfileBonds/GetAllItemsDynamic
     * @secure
     */
    userProfileBondsGetAllItemsDynamicCreate: (data: IQueryOption, params: RequestParams = {}) =>
      this.request<IUserProfileBondDynamicResponse, any>({
        path: `/api/UserProfileBonds/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileBonds
     * @name UserProfileBondsCreateCreate
     * @request POST:/api/UserProfileBonds/Create
     * @secure
     */
    userProfileBondsCreateCreate: (data: IUserProfileBond, params: RequestParams = {}) =>
      this.request<IUserProfileBond, any>({
        path: `/api/UserProfileBonds/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileBonds
     * @name UserProfileBondsPatchPartialUpdate
     * @request PATCH:/api/UserProfileBonds/Patch/{id}
     * @secure
     */
    userProfileBondsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<IUserProfileBond, any>({
        path: `/api/UserProfileBonds/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileHistAlerts
     * @name UserProfileHistAlertsDeleteDelete
     * @request DELETE:/api/UserProfileHistAlerts/Delete/{id}
     * @secure
     */
    userProfileHistAlertsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IUserProfileHistAlert, any>({
        path: `/api/UserProfileHistAlerts/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileHistAlerts
     * @name UserProfileHistAlertsGetAllDynamicList
     * @request GET:/api/UserProfileHistAlerts/GetAllDynamic
     * @secure
     */
    userProfileHistAlertsGetAllDynamicList: (
      query: IUserProfileHistAlertsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IUserProfileHistAlertDynamicResponse, any>({
        path: `/api/UserProfileHistAlerts/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileHistAlerts
     * @name UserProfileHistAlertsGetAllItemsDynamicCreate
     * @request POST:/api/UserProfileHistAlerts/GetAllItemsDynamic
     * @secure
     */
    userProfileHistAlertsGetAllItemsDynamicCreate: (
      data: IQueryOption,
      params: RequestParams = {},
    ) =>
      this.request<IUserProfileHistAlertDynamicResponse, any>({
        path: `/api/UserProfileHistAlerts/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileHistAlerts
     * @name UserProfileHistAlertsCreateCreate
     * @request POST:/api/UserProfileHistAlerts/Create
     * @secure
     */
    userProfileHistAlertsCreateCreate: (data: IUserProfileHistAlert, params: RequestParams = {}) =>
      this.request<IUserProfileHistAlert, any>({
        path: `/api/UserProfileHistAlerts/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileHistAlerts
     * @name UserProfileHistAlertsPatchPartialUpdate
     * @request PATCH:/api/UserProfileHistAlerts/Patch/{id}
     * @secure
     */
    userProfileHistAlertsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<IUserProfileHistAlert, any>({
        path: `/api/UserProfileHistAlerts/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfilePermissions
     * @name UserProfilePermissionsDeleteDelete
     * @request DELETE:/api/UserProfilePermissions/Delete/{id}
     * @secure
     */
    userProfilePermissionsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IUserProfilePermission, any>({
        path: `/api/UserProfilePermissions/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfilePermissions
     * @name UserProfilePermissionsGetAllDynamicList
     * @request GET:/api/UserProfilePermissions/GetAllDynamic
     * @secure
     */
    userProfilePermissionsGetAllDynamicList: (
      query: IUserProfilePermissionsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IUserProfilePermissionDynamicResponse, any>({
        path: `/api/UserProfilePermissions/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfilePermissions
     * @name UserProfilePermissionsGetAllItemsDynamicCreate
     * @request POST:/api/UserProfilePermissions/GetAllItemsDynamic
     * @secure
     */
    userProfilePermissionsGetAllItemsDynamicCreate: (
      data: IQueryOption,
      params: RequestParams = {},
    ) =>
      this.request<IUserProfilePermissionDynamicResponse, any>({
        path: `/api/UserProfilePermissions/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfilePermissions
     * @name UserProfilePermissionsCreateCreate
     * @request POST:/api/UserProfilePermissions/Create
     * @secure
     */
    userProfilePermissionsCreateCreate: (
      data: IUserProfilePermission,
      params: RequestParams = {},
    ) =>
      this.request<IUserProfilePermission, any>({
        path: `/api/UserProfilePermissions/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfilePermissions
     * @name UserProfilePermissionsPatchPartialUpdate
     * @request PATCH:/api/UserProfilePermissions/Patch/{id}
     * @secure
     */
    userProfilePermissionsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<IUserProfilePermission, any>({
        path: `/api/UserProfilePermissions/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfiles
     * @name UserProfilesDeleteDelete
     * @request DELETE:/api/UserProfiles/Delete/{id}
     * @secure
     */
    userProfilesDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IUserProfile, any>({
        path: `/api/UserProfiles/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfiles
     * @name UserProfilesGetAllDynamicList
     * @request GET:/api/UserProfiles/GetAllDynamic
     * @secure
     */
    userProfilesGetAllDynamicList: (
      query: IUserProfilesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IUserProfileDynamicResponse, any>({
        path: `/api/UserProfiles/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfiles
     * @name UserProfilesGetAllItemsDynamicCreate
     * @request POST:/api/UserProfiles/GetAllItemsDynamic
     * @secure
     */
    userProfilesGetAllItemsDynamicCreate: (data: IQueryOption, params: RequestParams = {}) =>
      this.request<IUserProfileDynamicResponse, any>({
        path: `/api/UserProfiles/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfiles
     * @name UserProfilesCreateCreate
     * @request POST:/api/UserProfiles/Create
     * @secure
     */
    userProfilesCreateCreate: (data: IUserProfile, params: RequestParams = {}) =>
      this.request<IUserProfile, any>({
        path: `/api/UserProfiles/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfiles
     * @name UserProfilesPatchPartialUpdate
     * @request PATCH:/api/UserProfiles/Patch/{id}
     * @secure
     */
    userProfilesPatchPartialUpdate: (id: string, data: IOperation[], params: RequestParams = {}) =>
      this.request<IUserProfile, any>({
        path: `/api/UserProfiles/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileStockArchives
     * @name UserProfileStockArchivesGetAllDynamicList
     * @request GET:/api/UserProfileStockArchives/GetAllDynamic
     * @secure
     */
    userProfileStockArchivesGetAllDynamicList: (
      query: IUserProfileStockArchivesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IUserProfileStockArchiveDynamicResponse, any>({
        path: `/api/UserProfileStockArchives/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileStockArchives
     * @name UserProfileStockArchivesGetAllItemsDynamicCreate
     * @request POST:/api/UserProfileStockArchives/GetAllItemsDynamic
     * @secure
     */
    userProfileStockArchivesGetAllItemsDynamicCreate: (
      data: IQueryOption,
      params: RequestParams = {},
    ) =>
      this.request<IUserProfileStockArchiveDynamicResponse, any>({
        path: `/api/UserProfileStockArchives/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileStockDefaultAlertArchives
     * @name UserProfileStockDefaultAlertArchivesGetAllDynamicList
     * @request GET:/api/UserProfileStockDefaultAlertArchives/GetAllDynamic
     * @secure
     */
    userProfileStockDefaultAlertArchivesGetAllDynamicList: (
      query: IUserProfileStockDefaultAlertArchivesGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IUserProfileStockDefaultAlertArchiveDynamicResponse, any>({
        path: `/api/UserProfileStockDefaultAlertArchives/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileStockDefaultAlertArchives
     * @name UserProfileStockDefaultAlertArchivesGetAllItemsDynamicCreate
     * @request POST:/api/UserProfileStockDefaultAlertArchives/GetAllItemsDynamic
     * @secure
     */
    userProfileStockDefaultAlertArchivesGetAllItemsDynamicCreate: (
      data: IQueryOption,
      params: RequestParams = {},
    ) =>
      this.request<IUserProfileStockDefaultAlertArchiveDynamicResponse, any>({
        path: `/api/UserProfileStockDefaultAlertArchives/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileStockDefaultAlerts
     * @name UserProfileStockDefaultAlertsDeleteDelete
     * @request DELETE:/api/UserProfileStockDefaultAlerts/Delete/{id}
     * @secure
     */
    userProfileStockDefaultAlertsDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IUserProfileStockDefaultAlert, any>({
        path: `/api/UserProfileStockDefaultAlerts/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileStockDefaultAlerts
     * @name UserProfileStockDefaultAlertsGetAllDynamicList
     * @request GET:/api/UserProfileStockDefaultAlerts/GetAllDynamic
     * @secure
     */
    userProfileStockDefaultAlertsGetAllDynamicList: (
      query: IUserProfileStockDefaultAlertsGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IUserProfileStockDefaultAlertDynamicResponse, any>({
        path: `/api/UserProfileStockDefaultAlerts/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileStockDefaultAlerts
     * @name UserProfileStockDefaultAlertsGetAllItemsDynamicCreate
     * @request POST:/api/UserProfileStockDefaultAlerts/GetAllItemsDynamic
     * @secure
     */
    userProfileStockDefaultAlertsGetAllItemsDynamicCreate: (
      data: IQueryOption,
      params: RequestParams = {},
    ) =>
      this.request<IUserProfileStockDefaultAlertDynamicResponse, any>({
        path: `/api/UserProfileStockDefaultAlerts/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileStockDefaultAlerts
     * @name UserProfileStockDefaultAlertsCreateCreate
     * @request POST:/api/UserProfileStockDefaultAlerts/Create
     * @secure
     */
    userProfileStockDefaultAlertsCreateCreate: (
      data: IUserProfileStockDefaultAlert,
      params: RequestParams = {},
    ) =>
      this.request<IUserProfileStockDefaultAlert, any>({
        path: `/api/UserProfileStockDefaultAlerts/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileStockDefaultAlerts
     * @name UserProfileStockDefaultAlertsPatchPartialUpdate
     * @request PATCH:/api/UserProfileStockDefaultAlerts/Patch/{id}
     * @secure
     */
    userProfileStockDefaultAlertsPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<IUserProfileStockDefaultAlert, any>({
        path: `/api/UserProfileStockDefaultAlerts/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileStocks
     * @name UserProfileStocksDeleteDelete
     * @request DELETE:/api/UserProfileStocks/Delete/{id}
     * @secure
     */
    userProfileStocksDeleteDelete: (id: string, params: RequestParams = {}) =>
      this.request<IUserProfileStock, any>({
        path: `/api/UserProfileStocks/Delete/${id}`,
        method: 'DELETE',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileStocks
     * @name UserProfileStocksGetAllDynamicList
     * @request GET:/api/UserProfileStocks/GetAllDynamic
     * @secure
     */
    userProfileStocksGetAllDynamicList: (
      query: IUserProfileStocksGetAllDynamicListParams,
      params: RequestParams = {},
    ) =>
      this.request<IUserProfileStockDynamicResponse, any>({
        path: `/api/UserProfileStocks/GetAllDynamic`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileStocks
     * @name UserProfileStocksGetAllItemsDynamicCreate
     * @request POST:/api/UserProfileStocks/GetAllItemsDynamic
     * @secure
     */
    userProfileStocksGetAllItemsDynamicCreate: (data: IQueryOption, params: RequestParams = {}) =>
      this.request<IUserProfileStockDynamicResponse, any>({
        path: `/api/UserProfileStocks/GetAllItemsDynamic`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileStocks
     * @name UserProfileStocksCreateCreate
     * @request POST:/api/UserProfileStocks/Create
     * @secure
     */
    userProfileStocksCreateCreate: (data: IUserProfileStock, params: RequestParams = {}) =>
      this.request<IUserProfileStock, any>({
        path: `/api/UserProfileStocks/Create`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags UserProfileStocks
     * @name UserProfileStocksPatchPartialUpdate
     * @request PATCH:/api/UserProfileStocks/Patch/{id}
     * @secure
     */
    userProfileStocksPatchPartialUpdate: (
      id: string,
      data: IOperation[],
      params: RequestParams = {},
    ) =>
      this.request<IUserProfileStock, any>({
        path: `/api/UserProfileStocks/Patch/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
}
