import { AppLoading } from 'components/app-loading';

import { APP_ROUTES } from 'configs';
import { ProviderInputsTheme } from 'contexts/theme/theme-input-white';
import { EmptyLayout, LoginLayout, PrivateLayout } from 'layouts';
import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { RequireAuth } from 'router/requred-auth';

const PageDashboard = React.lazy(
  () => import(/* webpackChunkName: "dashboard" */ 'pages/dashboard'),
);
const PageReportYieldExtreme = React.lazy(
  () => import(/* webpackChunkName: "report-yield-shekel" */ 'pages/report-yield-extreme'),
);
const PageReportYieldLinked = React.lazy(
  () => import(/* webpackChunkName: "report-yield-linked" */ 'pages/report-yield-linked'),
);
const PageReportYieldShekel = React.lazy(
  () => import(/* webpackChunkName: "report-yield-shekel" */ 'pages/report-yield-shekel'),
);

const PageReportDeltaLinked = React.lazy(
  () => import(/* webpackChunkName: "report-delta-linked" */ 'pages/report-delta-linked'),
);
const PageReportDeltaShekel = React.lazy(
  () => import(/* webpackChunkName: "report-delta-shekel" */ 'pages/report-delta-shekel'),
);

const PageReportYieldLinkedMarket = React.lazy(
  () =>
    import(/* webpackChunkName: "report-yield-linked-market" */ 'pages/report-yield-linked-market'),
);
const PageReportYieldShekelMarket = React.lazy(
  () =>
    import(/* webpackChunkName: "report-yield-shekel-market" */ 'pages/report-yield-shekel-market'),
);
const PageReportDeltaLinkedMarket = React.lazy(
  () =>
    import(/* webpackChunkName: "report-delta-linked-market" */ 'pages/report-delta-linked-market'),
);
const PageReportDeltaShekelMarket = React.lazy(
  () =>
    import(/* webpackChunkName: "report-delta-shekel-market" */ 'pages/report-delta-shekel-market'),
);

const PageReportGrossYieldLinkedMarket = React.lazy(
  () =>
    import(
      /* webpackChunkName: "report-gross-yield-linked-market" */ 'pages/report-gross-yield-linked-market'
    ),
);
const PageReportGrossYieldShekelMarket = React.lazy(
  () =>
    import(
      /* webpackChunkName: "report-gross-yield-shekel-market" */ 'pages/report-gross-yield-shekel-market'
    ),
);
const PageReportGrossDeltaLinkedMarket = React.lazy(
  () =>
    import(
      /* webpackChunkName: "report-gross-delta-linked-market" */ 'pages/report-gross-delta-linked-market'
    ),
);
const PageReportGrossDeltaShekelMarket = React.lazy(
  () =>
    import(
      /* webpackChunkName: "report-gross-delta-shekel-market" */ 'pages/report-gross-delta-shekel-market'
    ),
);

const PageReportGrossYieldLinked = React.lazy(
  () =>
    import(/* webpackChunkName: "report-gross-yield-linked" */ 'pages/report-gross-yield-linked'),
);
const PageReportGrossYieldShekel = React.lazy(
  () =>
    import(/* webpackChunkName: "report-gross-yield-shekel" */ 'pages/report-gross-yield-shekel'),
);

const PageReportGrossDeltaLinked = React.lazy(
  () =>
    import(/* webpackChunkName: "report-gross-delta-linked" */ 'pages/report-gross-delta-linked'),
);
const PageReportGrossDeltaShekel = React.lazy(
  () =>
    import(/* webpackChunkName: "report-gross-delta-shekel" */ 'pages/report-gross-delta-shekel'),
);

const PageReportsPortfolioSummary = React.lazy(
  () =>
    import(
      /* webpackChunkName: "reports-portfolio-summary-tab" */ 'pages/reports-portfolio-summary'
    ),
);
const PageProfile = React.lazy(() => import(/* webpackChunkName: "profile" */ 'pages/profile'));

const PageOnlineDelta = React.lazy(
  () => import(/* webpackChunkName: "online-delta" */ 'pages/todo'),
);
const PageOnlineYield = React.lazy(
  () => import(/* webpackChunkName: "online-yield" */ 'pages/todo'),
);
const PageOnlineBonds = React.lazy(
  () => import(/* webpackChunkName: "bond-online" */ 'pages/bond-online'),
);
const PageOnlineStocks = React.lazy(
  () => import(/* webpackChunkName: "online-stocks" */ 'pages/stock-online'),
);
const PageOnlineAlerts = React.lazy(
  () => import(/* webpackChunkName: "online-alerts" */ 'pages/online-alerts'),
);

const PagePortfolioDelta = React.lazy(
  () => import(/* webpackChunkName: "portfolio-delta" */ 'pages/delta-portfolio'),
);
const PagePortfolioYield = React.lazy(
  () => import(/* webpackChunkName: "portfolio-yield" */ 'pages/yield-portfolio'),
);
const PagePortfolioBonds = React.lazy(
  () => import(/* webpackChunkName: "portfolio-yield" */ 'pages/bond-portfolio'),
);

const PagePortfolioStocks = React.lazy(
  () => import(/* webpackChunkName: "portfolio-yield" */ 'pages/stock-portfolio'),
);

const PageAlertsDelta = React.lazy(
  () => import(/* webpackChunkName: "alerts-delta" */ 'pages/delta-alerts'),
);
const PageAlertsYield = React.lazy(
  () => import(/* webpackChunkName: "alerts-yield" */ 'pages/yield-alerts'),
);
const PageAlertsStockPerformance = React.lazy(
  () => import(/* webpackChunkName: "alerts-stock-performance" */ 'pages/stock-alerts'),
);
const PageAlertsStockYield = React.lazy(
  () => import(/* webpackChunkName: "alerts-stock-yield" */ 'pages/stock-yield-alerts'),
);
const PageAlertsConfiguration = React.lazy(
  () => import(/* webpackChunkName: "alerts-configuration" */ 'pages/alerts-configuration'),
);
const PageAlertsHistory = React.lazy(
  () => import(/* webpackChunkName: "alerts-history" */ 'pages/alerts-history'),
);

const PageRawBondsData = React.lazy(
  () => import(/* webpackChunkName: "raw-bonds-data" */ 'pages/raw-data-bonds'),
);
const PageRawStocksData = React.lazy(
  () => import(/* webpackChunkName: "raw-stocks-data" */ 'pages/raw-data-stocks'),
);

const PageArchiveBonds = React.lazy(
  () => import(/* webpackChunkName: "archive-bonds" */ 'pages/bond-archives'),
);
const PageArchiveBondsRawData = React.lazy(
  () => import(/* webpackChunkName: "bond-raw-data-archives" */ 'pages/bond-raw-data-archives'),
);

const PageArchiveStocks = React.lazy(
  () => import(/* webpackChunkName: "archive-stocks" */ 'pages/stock-archives'),
);
const PageArchiveStocksRawData = React.lazy(
  () => import(/* webpackChunkName: "archive-stocks-raw-data" */ 'pages/stock-raw-data-archives'),
);
const PageArchiveMidroogRatings = React.lazy(
  () => import(/* webpackChunkName: "archive-midroog-ratings" */ 'pages/midroog-rating-archives'),
);
const PageArchiveMaalotRatings = React.lazy(
  () => import(/* webpackChunkName: "archive-maalot-ratings" */ 'pages/maalot-rating-archives'),
);
const PageArchiveUsers = React.lazy(
  () => import(/* webpackChunkName: "archive-users" */ 'pages/user-profile-archives'),
);
const PageArchiveLogs = React.lazy(
  () => import(/* webpackChunkName: "archive-logs" */ 'pages/todo'),
);

const PageUsers = React.lazy(() => import(/* webpackChunkName: "users" */ 'pages/user-profiles'));
const PageUsersPermissions = React.lazy(
  () => import(/* webpackChunkName: "user-permissions" */ 'pages/user-permissions'),
);

const PageLabels = React.lazy(() => import(/* webpackChunkName: "labels" */ 'pages/labels'));
const PageLanguages = React.lazy(
  () => import(/* webpackChunkName: "languages" */ 'pages/languages'),
);
const PageExcludeDates = React.lazy(
  () => import(/* webpackChunkName: "exclude-dates" */ 'pages/exclude-dates'),
);
const PageGtoEndpoints = React.lazy(
  () => import(/* webpackChunkName: "gto-endpoints" */ 'pages/gto-endpoints'),
);

const PageFillingMissingDate = React.lazy(
  () => import(/* webpackChunkName: "filling-missing-date" */ 'pages/filling-missing-date'),
);
const PageDataEtl = React.lazy(() => import(/* webpackChunkName: "data-etl" */ 'pages/data-etl'));

const PageIssuers = React.lazy(() => import(/* webpackChunkName: "issuers" */ 'pages/issuers'));
const PageBranches = React.lazy(
  () => import(/* webpackChunkName: "branches" */ 'pages/branches-parameters'),
);
const PageLinkedAssets = React.lazy(
  () => import(/* webpackChunkName: "linked-assets" */ 'pages/linked-assets-parameters'),
);
const PageEquityTypes = React.lazy(
  () => import(/* webpackChunkName: "equity-types" */ 'pages/equity-types-parameters'),
);
const PageTradeStatuses = React.lazy(
  () => import(/* webpackChunkName: "trade-statuses" */ 'pages/trade-statuses-parameters'),
);
const PageMidroogRatings = React.lazy(
  () => import(/* webpackChunkName: "midroog-ratings" */ 'pages/midroog-ratings-parameters'),
);
const PageMaalotRatings = React.lazy(
  () => import(/* webpackChunkName: "maalot_ratings" */ 'pages/maalot-ratings-parameters'),
);

// const PageAlertsSystemDefault = React.lazy(
//   () => import(/* webpackChunkName: "alerts-system-default" */ 'pages/alerts-system-default'),
// );
const PageAlertsPortfolioDefault = React.lazy(
  () => import(/* webpackChunkName: "alerts-portfolio-default" */ 'pages/alerts-portfolio-default'),
);

const PageMigrations = React.lazy(() => import(/* webpackChunkName: "migrations" */ 'pages/todo'));

const PageLogs = React.lazy(() => import(/* webpackChunkName: "app-logs" */ 'pages/app-logs'));

const PageHelp = React.lazy(() => import(/* webpackChunkName: "help" */ 'pages/help'));

// rest
const PageLogin = React.lazy(() => import(/* webpackChunkName: "home" */ 'pages/login'));
const PageLoginCode = React.lazy(() => import(/* webpackChunkName: "home" */ 'pages/login-code'));
const PageNotFound = React.lazy(
  () => import(/* webpackChunkName: "not-found" */ 'pages/not-found'),
);

export const routes = createBrowserRouter([
  {
    element: (
      <RequireAuth>
        <PrivateLayout />
      </RequireAuth>
    ),
    children: [
      // Start Hidden
      {
        path: APP_ROUTES.PROFILE.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageProfile />
          </React.Suspense>
        ),
      },
      // End Hidden

      {
        path: APP_ROUTES.DASHBOARD.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageDashboard />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.REPORTS_PORTFOLIO_SUMMARY.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageReportsPortfolioSummary />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.REPORT_YIELD_EXTREME.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageReportYieldExtreme />
          </React.Suspense>
        ),
      },

      {
        path: APP_ROUTES.REPORT_YIELD_LINKED.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageReportYieldLinked />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.REPORT_YIELD_SHEKEL.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageReportYieldShekel />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.REPORT_DELTA_LINKED.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageReportDeltaLinked />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.REPORT_DELTA_SHEKEL.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageReportDeltaShekel />
          </React.Suspense>
        ),
      },

      {
        path: APP_ROUTES.REPORT_YIELD_LINKED_MARKET.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageReportYieldLinkedMarket />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.REPORT_YIELD_SHEKEL_MARKET.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageReportYieldShekelMarket />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.REPORT_DELTA_LINKED_MARKET.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageReportDeltaLinkedMarket />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.REPORT_DELTA_SHEKEL_MARKET.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageReportDeltaShekelMarket />
          </React.Suspense>
        ),
      },

      {
        path: APP_ROUTES.REPORT_GROSS_YIELD_LINKED.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageReportGrossYieldLinked />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.REPORT_GROSS_YIELD_SHEKEL.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageReportGrossYieldShekel />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.REPORT_GROSS_DELTA_LINKED.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageReportGrossDeltaLinked />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.REPORT_GROSS_DELTA_SHEKEL.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageReportGrossDeltaShekel />
          </React.Suspense>
        ),
      },

      {
        path: APP_ROUTES.REPORT_GROSS_YIELD_LINKED_MARKET.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageReportGrossYieldLinkedMarket />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.REPORT_GROSS_YIELD_SHEKEL_MARKET.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageReportGrossYieldShekelMarket />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.REPORT_GROSS_DELTA_LINKED_MARKET.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageReportGrossDeltaLinkedMarket />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.REPORT_GROSS_DELTA_SHEKEL_MARKET.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageReportGrossDeltaShekelMarket />
          </React.Suspense>
        ),
      },

      {
        path: APP_ROUTES.ONLINE_DELTA.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageOnlineDelta />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.ONLINE_YIELD.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageOnlineYield />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.ONLINE_BONDS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageOnlineBonds />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.ONLINE_STOCKS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageOnlineStocks />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.ONLINE_ALERTS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageOnlineAlerts />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.PORTFOLIO_DELTA.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PagePortfolioDelta />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.PORTFOLIO_YIELD.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PagePortfolioYield />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.PORTFOLIO_STOCKS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PagePortfolioStocks />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.PORTFOLIO_BONDS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PagePortfolioBonds />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.ALERTS_DELTA.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageAlertsDelta />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.ALERTS_YIELD.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageAlertsYield />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.ALERTS_STOCK_PERFORMANCE.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageAlertsStockPerformance />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.ALERTS_STOCK_YIELD.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageAlertsStockYield />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.ALERTS_CONFIGURATION.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageAlertsConfiguration />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.ALERTS_HISTORY.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageAlertsHistory />
          </React.Suspense>
        ),
      },

      {
        path: APP_ROUTES.RAW_DATA_BONDS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageRawBondsData />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.RAW_DATA_STOCKS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageRawStocksData />
          </React.Suspense>
        ),
      },

      {
        path: APP_ROUTES.ARCHIVE_BONDS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageArchiveBonds />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.ARCHIVE_BONDS_RAW.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageArchiveBondsRawData />
          </React.Suspense>
        ),
      },

      {
        path: APP_ROUTES.ARCHIVE_STOCKS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageArchiveStocks />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.ARCHIVE_STOCKS_RAW.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageArchiveStocksRawData />
          </React.Suspense>
        ),
      },

      {
        path: APP_ROUTES.ARCHIVE_MIDROOG_RATINGS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageArchiveMidroogRatings />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.ARCHIVE_MAALOT_RATINGS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageArchiveMaalotRatings />
          </React.Suspense>
        ),
      },

      {
        path: APP_ROUTES.ARCHIVE_USERS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageArchiveUsers />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.ARCHIVE_LOGS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageArchiveLogs />
          </React.Suspense>
        ),
      },

      {
        path: APP_ROUTES.USERS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageUsers />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.USER_PERMISSIONS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageUsersPermissions />
          </React.Suspense>
        ),
      },

      {
        path: APP_ROUTES.LABELS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageLabels />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.LANGUAGES.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageLanguages />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.EXCLUDE_DATES.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageExcludeDates />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.GTO_ENDPOINTS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageGtoEndpoints />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.FILLING_MISSING_DATE.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageFillingMissingDate />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.DATA_ETL.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageDataEtl />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.ISSUERS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageIssuers />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.BRANCHES.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageBranches />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.LINKED_ASSETS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageLinkedAssets />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.EQUITY_TYPES.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageEquityTypes />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.TRADE_STATUSES.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageTradeStatuses />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.MIDROOG_RATINGS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageMidroogRatings />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.MAALOT_RATINGS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageMaalotRatings />
          </React.Suspense>
        ),
      },

      // {
      //   path: APP_ROUTES.ALERTS_SYSTEM_DEFAULT.path,
      //   element: (
      //     <React.Suspense fallback={<AppLoading />}>
      //       <PageAlertsSystemDefault />
      //     </React.Suspense>
      //   ),
      // },
      {
        path: APP_ROUTES.ALERTS_PORTFOLIO_DEFAULT.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageAlertsPortfolioDefault />
          </React.Suspense>
        ),
      },

      {
        path: APP_ROUTES.MIGRATIONS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageMigrations />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.LOGS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageLogs />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.HELP.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageHelp />
          </React.Suspense>
        ),
      },

      {
        path: '*',
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageNotFound />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    element: (
      <ProviderInputsTheme>
        <LoginLayout />
      </ProviderInputsTheme>
    ),
    children: [
      {
        path: APP_ROUTES.LOGIN.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageLogin />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.LOGIN_CODE.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageLoginCode />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    element: <EmptyLayout />,
    children: [
      {
        path: '*',
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageNotFound />
          </React.Suspense>
        ),
      },
    ],
  },
]);
