import { createSelector } from '@reduxjs/toolkit';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { isEqual, orderBy } from 'lodash-es';
import { apiBondsHelper, makeBondGrossMarketSources } from 'services/bonds-helper';
import { AppState } from '../index';

const selectState = (state: AppState) => state.reportGrossDeltaLinkedMarket;

const selectFilters = createSelector(selectState, (state) => {
  return state.filters;
});
const selectGroupByRating = createSelector(selectState, ({ isGroupByRating }) => {
  return isGroupByRating;
});
const selectStatType = createSelector(selectState, ({ statType }) => {
  return statType;
});

const selectData = createSelector(selectState, (state) => {
  return state.data;
});
const selectOrder = createSelector(selectState, (state) => {
  return state.orderBy;
});

const selectSources = createSelector(selectData, (rows) => {
  return makeBondGrossMarketSources(rows);
});

const selectDataFiltered = createSelector(selectData, selectFilters, (rows, filters) => {
  return rows.filter((row) => {
    const res: Array<boolean> = [];

    const handleArrayIncludes = (arr: string[], rowValue: any) => {
      if (arr.length > 0) {
        const includes = arr.includes(String(rowValue));
        res.push(includes);
      }
    };

    handleArrayIncludes(filters.bonds, row.symbol);
    handleArrayIncludes(filters.issuers, row.issuerName);

    handleArrayIncludes(filters.ratingGroups, row.ratingGroup);
    handleArrayIncludes(filters.midroogRatings, row.midroogRating);
    handleArrayIncludes(filters.maalotRatings, row.maalotRating);
    handleArrayIncludes(filters.marketRatings, row.marketRating);

    handleArrayIncludes(filters.equityTypes, row.equityTypeName);
    handleArrayIncludes(filters.branches, row.branchName);

    return res.every(Boolean);
  });
});

const selectDataSorted = createSelector(selectDataFiltered, selectOrder, (rows, order) => {
  if (!order.order) {
    return rows;
  }

  return orderBy(rows, [order.field], [order.order]);
});

const selectDataSelected = createSelector(selectDataFiltered, (rows) => {
  return rows.filter((row) => {
    return row.__isSelected;
  });
});
const selectSelectedSymbols = createSelector(selectDataSelected, (rows) => {
  return rows.map(({ symbol }) => symbol);
});

const selectedSymbols = createSelector(selectState, ({ symbols }) => {
  return symbols;
});

const selectIsApplyAble = createSelector(
  selectSelectedSymbols,
  selectedSymbols,
  (selectedSymbols, symbols) => {
    if (symbols.length === 0) return false;

    const res = isEqual(selectedSymbols, symbols);

    return !res;
  },
);

const selectAnalyticsArgs = createSelector(
  selectedSymbols,
  selectFilters,
  selectGroupByRating,
  selectStatType,
  (symbols, { date, grossDuration, value }, ratingGroup, statType) => {
    return {
      symbols: symbols,
      minGrossDuration: grossDuration[0] ?? -1000,
      maxGrossDuration: grossDuration[1] ?? 1000,
      minValue: value[0] ?? -1000,
      maxValue: value[1] ?? 1000,
      minDate: date[0],
      maxDate: date[1],
      ratingGroup: ratingGroup,
      statType,
    };
  },
);
const selectStatuses = createSelector(selectState, ({ isLoading, isInit }) => {
  return { isLoading, isInit };
});

const selectAnalyticsLoading = createSelector(
  (state: AppState) => state,
  selectAnalyticsArgs,
  (state, args) => {
    const items = [
      apiBondsHelper.endpoints.getBondGrossReportDeltaLinkedMarket.select(args)(state),
    ];

    return items.some(({ status }) => status === QueryStatus.pending);
  },
);

export const selectorsReportGrossDeltaLinkedMarket = {
  filters: selectFilters,
  orderBy: selectOrder,
  sources: selectSources,
  data: selectData,
  dataSelected: selectDataSelected,
  dataFiltered: selectDataSorted,
  loadingAnalytics: selectAnalyticsLoading,
  statuses: selectStatuses,
  analyticsArgs: selectAnalyticsArgs,
  isGrouped: selectGroupByRating,
  isApplyAble: selectIsApplyAble,
  statType: selectStatType,
};
