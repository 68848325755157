import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addDays } from 'date-fns';
import { DynamicOrder } from 'utils/dynamic';
import { selectItemsAll, toggleItem } from 'utils/other';
import { actionReportDeltaShekelMarketFetch } from './actions';
import { IReportDeltaShekelMarketItem, PREFIX } from './helpers';

export interface ReportDeltaShekelMarketFilters {
  date: string;
  bonds: string[];
  issuers: string[];
  branches: string[];
  equityTypeNames: string[];
  midroogRatings: string[];
  maalotRatings: string[];
  marketRatings: string[];
}

interface InitState {
  isInit: boolean;
  filters: ReportDeltaShekelMarketFilters;
  data: Array<IReportDeltaShekelMarketItem>;
  isLoading: boolean;
  isGroupByRating: boolean;
  symbols: number[];
  orderBy: DynamicOrder;
}

export const initState = (): InitState => {
  return {
    isInit: false,
    isLoading: false,
    isGroupByRating: false,
    data: [],
    filters: {
      // backend
      date: addDays(new Date(), -1).toISOString(),
      // frontend
      bonds: [],
      issuers: [],
      branches: [],
      equityTypeNames: [],
      midroogRatings: [],
      maalotRatings: [],
      marketRatings: [],
    },
    symbols: [],
    orderBy: {
      field: 'symbol',
      order: null,
    },
  };
};

const slice = createSlice({
  name: PREFIX,
  initialState: initState(),
  reducers: {
    init() {},
    destroy() {},
    setFilters(state, action: PayloadAction<ReportDeltaShekelMarketFilters>) {
      state.filters =
        state.filters.date !== action.payload.date
          ? {
              ...initState().filters,
              date: action.payload.date,
            }
          : action.payload;
    },
    toggleItem(state, action: PayloadAction<{ id: string }>) {
      toggleItem(state.data, action.payload.id);
    },
    toggleAll(state, action: PayloadAction<{ value: boolean }>) {
      selectItemsAll(state.data, action.payload);
    },
    toggleGroupByRating(state) {
      state.isGroupByRating = !state.isGroupByRating;
    },
    applySymbols(state, action: PayloadAction<number[]>) {
      state.symbols = action.payload;
    },
    setOrder(state, action: PayloadAction<DynamicOrder>) {
      state.orderBy = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionReportDeltaShekelMarketFetch.pending, (state, action) => {
        state.isLoading = true;
        if (action.meta.arg) {
          state.filters.date = action.meta.arg.initDate;
        }
      })
      .addCase(actionReportDeltaShekelMarketFetch.fulfilled, (state, action) => {
        state.symbols = [];
        state.data = [];

        state.isLoading = false;
        action.payload.forEach((item) => {
          state.data.push({ ...item, __isSelected: true });
          state.symbols.push(item.symbol);
        });
      })
      .addCase(actionReportDeltaShekelMarketFetch.rejected, (state, action) => {
        if (action.meta.aborted) {
          return state;
        }
        state.isLoading = false;
      });
  },
});
export const actionsReportDeltaShekelMarket = slice.actions;
export const reducerReportDeltaShekelMarket = slice.reducer;
